import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const FormModal = styled(Modal)`
  .modal-content {
    border-radius: 1.6rem;
    padding: 1rem;
  }
  .modal-header {
    border-bottom: none;
    .modal-title {
      line-height: 1.25;
      font-size: 1.25rem;
    }
  }
  .modal-footer {
    border-top: none;
  }
`

const REASON = {
  NOT_APPROVED: 'User is not pre-approved for the portal selected',
  OTHER: 'Other (specify in the Comments below)'
}

const DenyRequestModal = ({ show, onConfirm, onCancel }) => {
  const [reason, setReason] = useState(REASON.NOT_APPROVED)
  const [comment, setComment] = useState('')

  const onReasonChange = (event) => setReason(event.target.value)
  const onCommentChange = (event) => setComment(event.target.value)

  const init = () => {
    setReason(REASON.NOT_APPROVED)
    setComment('')
  }
  const onConfirmClick = () => {
    onConfirm({ reason, comment })
    init()
  }
  const onCancelClick = () => {
    onCancel()
    init()
  }

  return (
    <FormModal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          Please select a reason for denying the user’s access request:
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='py-0'>
        <Form.Group controlId='reason'>
          <Form.Label>Reason for Denial:</Form.Label>
          <Form.Control as='select' onChange={onReasonChange} value={reason}>
            <option value={REASON.NOT_APPROVED}>
              User is not pre-approved for the portal selected
            </option>
            <option value={REASON.OTHER}>
              Other (specify in the Comments below)
            </option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId='comments'>
          <Form.Label>Comments to Requester (Optional):</Form.Label>
          <Form.Control value={comment} onChange={onCommentChange} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onConfirmClick}>
          Confirm
        </Button>
        <Button variant='outline-dark' onClick={onCancelClick}>
          Cancel
        </Button>
      </Modal.Footer>
    </FormModal>
  )
}

DenyRequestModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export { DenyRequestModal }
