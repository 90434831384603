import React, { useState } from 'react'
import {
  useAdminAffiliations,
  useDeleteAffiliationMutation,
  useUpdateAffiliationMutation
} from '../../hooks'
import {
  AffiliationsTable,
  ConfirmModal,
  ContentContainer,
  SearchInput,
  SearchSplash
} from '../../components'
import { Col, Row } from 'react-bootstrap'

const AffiliationsList = () => {
  const [searchText, setSearchText] = useState('')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState([])
  const [toDelete, setToDelete] = useState()

  const { affiliations, total, pageCount } = useAdminAffiliations({
    search,
    page,
    sort
  })
  const { mutate: updateAffiliation } = useUpdateAffiliationMutation()
  const { mutate: deleteAffiliation } = useDeleteAffiliationMutation()

  const handleSearch = () => {
    setSearch(searchText)
    setPage(1)
    setSort([])
  }

  const handlePageChange = (data) => {
    const newPage = data.selected + 1
    if (newPage !== page) {
      setPage(data.selected + 1)
    }
  }

  const handleSort = (data) => {
    if (data !== sort) {
      setPage(1)
      setSort(data)
    }
  }

  const handleUpdate = (id, property, value) => {
    updateAffiliation({ id, property, value })
  }

  const handleDelete = (id) => {
    setToDelete(affiliations.find((affiliation) => affiliation.id === id))
  }

  const handleConfirm = () => {
    deleteAffiliation({ id: toDelete.id })
    setToDelete(undefined)
  }

  return (
    <ContentContainer>
      <Row className='pb-3'>
        <Col xs={12} sm={8} md={6} lg={4}>
          <SearchInput
            text='Search by name'
            search={searchText}
            onSearchChange={setSearchText}
            onSearch={handleSearch}
          />
        </Col>
      </Row>
      {affiliations && affiliations.length ? (
        <AffiliationsTable
          maxOrderNumber={total}
          affiliations={affiliations}
          page={page - 1}
          pageCount={pageCount}
          onPageChange={handlePageChange}
          sort={sort}
          onSort={handleSort}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      ) : (
        <SearchSplash text='Sorry, no results found' />
      )}
      <ConfirmModal
        show={Boolean(toDelete)}
        title={`Are you sure you want to delete the group “${
          toDelete ? toDelete.name : ''
        }”?`}
        body='Users affiliated with this group will no longer have access to all the content after the deletion, and the group name will be removed from the list. This action cannot be undone unless by contacting the tech team.'
        onConfirm={handleConfirm}
        onCancel={() => setToDelete(undefined)}
      />
    </ContentContainer>
  )
}

export { AffiliationsList }
