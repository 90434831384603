import React from 'react'
import { Col, Image, Nav, Navbar, Row } from 'react-bootstrap'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const RowStyled = styled(Row)`
  .col-navbar {
    margin-top: auto;
    .navbar {
      border-radius: 1.6rem;
      .dropdown-menu {
        position: absolute;
      }
    }
  }
  .logo {
    img {
      width: 100%;
      @media (max-width: 575px) {
        width: 260px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
      }
    }
  }
`

const TitleToolbar = ({
  title,
  children,
  logoSrc,
  logoAlt,
  className,
  expand = false,
  ...other
}) => (
  <RowStyled className={className}>
    <Col
      className='col-navbar'
      xs={{ span: 12, order: 1 }}
      sm={{ span: 8, order: 0 }}
    >
      <Navbar as='div' expand={expand} {...other}>
        <Navbar.Brand as='h1' className='mr-auto pr-2'>
          {title}
        </Navbar.Brand>
        {children && (
          <Nav as='div' id='title-toolbar-nav' className='d-flex flex-row'>
            {children}
          </Nav>
        )}
      </Navbar>
    </Col>
    <Col className='logo' xs={{ span: 12, order: 0 }} sm={4}>
      <a href='/'>
        <Image src={logoSrc} alt={logoAlt} />
      </a>
    </Col>
  </RowStyled>
)

TitleToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  logoSrc: PropTypes.string,
  logoAlt: PropTypes.string,
  className: PropTypes.string,
  expand: ({ expand }, propName, componentName) =>
    expand && Error(`Invalid prop '${propName}' passed to '${componentName}'.`)
}
export { TitleToolbar }
