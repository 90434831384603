import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from 'react-oauth2'
import { env } from '../../helpers'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const PER_PAGE = 10

const useAdminAffiliations = ({ search, page, sort }) => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)

  const getAdminAffiliations = async () => {
    const offset = (page - 1) * PER_PAGE
    const sortParams = sort.reduce((result, item) => {
      result += `&sort.${item.id}=${item.desc ? 'desc' : 'asc'}`
      return result
    }, '')
    const searchParam = search ? `&search=${search}` : ''
    const url = encodeURI(
      `${env.apiUrl}/affiliations?max=${PER_PAGE}` +
        `&offset=${offset}${sortParams}${searchParam}`
    )
    const response = await securedFetch(url)
    await handleResponse(response)
    return response.json()
  }

  const query = useQuery(
    ['admin-affiliations', search, page, sort],
    getAdminAffiliations
  )

  useEffect(() => {
    if (query.status === 'loading') {
      progressStart()
    } else if (query.status === 'success') {
      progressEnd()
    } else if (query.status === 'error') {
      handleError(query.error, 'Error getting groups')
      progressEnd()
    }
  }, [handleError, progressEnd, progressStart, query.error, query.status])

  return {
    affiliations: query.data ? query.data.items : undefined,
    count: query.data ? query.data.count : undefined,
    total: query.data ? query.data.total : undefined,
    pageCount: query.data ? Math.ceil(query.data.count / PER_PAGE) : undefined
  }
}

export { useAdminAffiliations }
