import React from 'react'
import { DangerAlert, SuccessAlert } from '../alert'
import { Progress } from '../progress'
import styled from 'styled-components'

const StatusContainer = styled.div`
  position: sticky;
  top: 72px;
  width: 100%;
  z-index: 1000;
`

const Status = () => (
  <StatusContainer>
    <Progress />
    <SuccessAlert />
    <DangerAlert />
  </StatusContainer>
)

export { Status }
