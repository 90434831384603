import React, { useState } from 'react'
import { propTypes } from '../../helpers'
import { Document, Page } from 'react-pdf/dist/entry.webpack'

const VisualizationPdf = ({ visualization }) => {
  const [pageCount, setPageCount] = useState()

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageCount(numPages)
  }

  return (
    <Document
      file={`data:${visualization.contentType};base64,${visualization.content}`}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(pageCount), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
  )
}

VisualizationPdf.propTypes = {
  visualization: propTypes.visualizationPropType.isRequired
}

export { VisualizationPdf }
