import PropTypes from 'prop-types'

const affiliationPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  urlSlug: PropTypes.string.isRequired,
  permissions: PropTypes.array.isRequired
})

const categoryPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  urlSlug: PropTypes.string.isRequired,
  categoryType: PropTypes.string.isRequired
})

const categoryArrayPropType = PropTypes.arrayOf(categoryPropType)

const documentPropType = PropTypes.shape({
  id: PropTypes.number,
  key: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  modified: PropTypes.number,
  size: PropTypes.number
})

const documentArrayPropType = PropTypes.arrayOf(documentPropType)

const storyPropType = PropTypes.shape({
  id: PropTypes.number,
  content: PropTypes.string.isRequired
})

const storyArrayPropType = PropTypes.arrayOf(storyPropType)

const visualizationPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  description: PropTypes.string,
  longDescription: PropTypes.string,
  group: PropTypes.string
})

export const propTypes = {
  affiliationPropType,
  categoryArrayPropType,
  categoryPropType,
  documentArrayPropType,
  documentPropType,
  storyArrayPropType,
  storyPropType,
  visualizationPropType
}
