import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SliderPanel = styled.div.attrs((props) => ({
  visible: props.visible
}))`
  background-color: #ddd;
  .slider-content {
    background-color: #fff;
    max-height: 100%;
  }
  @media (min-width: 768px) {
    position: fixed;
    top: 14.3rem;
    right: 0;
    width: 50vw;
    z-index: 2000;
    transition: transform 0.4s cubic-bezier(0, 0.52, 0, 1);
    transform: translate3d(
      ${(props) => (props.visible ? '0vw' : '50vw')},
      0,
      0
    );
    max-height: calc(100% - 65px);
    padding: 0.5rem 0rem 0.5rem 0.5rem;
  }
  @media (max-width: 767px) {
    ${(props) => props.visible || 'display: none'}
  }
`

const Slider = ({ children, visible, className }) => (
  <SliderPanel className={className} visible={visible}>
    <div className='slider-content'>{children}</div>
  </SliderPanel>
)

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  className: PropTypes.string
}

export { Slider }
