import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from 'react-oauth2'
import { env } from '../../helpers'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useUser = () => {
  const { loggedIn, securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()

  const getUser = async () => {
    const url = encodeURI(`${env.apiUrl}/userInfo`)
    const response = await securedFetch(url)
    await handleResponse(response)
    const data = await response.json()
    data.disableTour = !data.enableTour
    data.fullName = [data.firstName, data.lastName].filter(Boolean).join(' ')
    return data
  }

  const query = useQuery('user', getUser, {
    enabled: !!loggedIn
  })

  useEffect(() => {
    query.status === 'error' && handleError(query.error, 'Error getting user')
  }, [handleError, query.error, query.status])

  return {
    user: loggedIn ? query.data : undefined
  }
}

export { useUser }
