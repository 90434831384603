import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '../icon'

const SearchInput = ({ placeholder, search, onSearchChange, onSearch }) => {
  const handleSubmit = (e) => {
    e.preventDefault()
    onSearch()
  }

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup>
        <Form.Control
          aria-label='search'
          placeholder={placeholder}
          value={search}
          onChange={(e) => onSearchChange(e.target.value)}
        />
        <InputGroup.Append>
          <Button aria-label='search' type='submit'>
            <Icon icon={faSearch} size='lg' />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  )
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  search: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired
}

export { SearchInput }
