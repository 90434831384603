const dataToDocument = (data, affiliationSlug, categorySlug) => {
  let name = data.path.split('/')
  name = name.length ? name[name.length - 1] : ''
  const url =
    data.path.slice(-1) !== '/'
      ? encodeURI(
          `${window.location.origin}/${affiliationSlug}/${categorySlug}/${data.id}`
        )
      : ''

  return {
    ...data,
    key: data.path,
    name,
    url,
    modified: Date.parse(data.lastUpdated),
    size: data.length
  }
}

export { dataToDocument }
