import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from 'react-oauth2'
import { env } from '../../helpers'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useStories = (categoryId) => {
  const { loggedIn, securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)

  const getStories = async () => {
    const fetchFn = loggedIn ? securedFetch : fetch
    const url = encodeURI(
      `${
        loggedIn ? env.apiUrl : env.publicApiUrl
      }/categories/${categoryId}/stories`
    )
    const response = await fetchFn(url)
    await handleResponse(response)
    return response.json()
  }

  const query = useQuery(['stories', categoryId], getStories, {
    enabled: !!categoryId
  })

  useEffect(() => {
    if (query.status === 'loading') {
      progressStart()
    } else if (query.status === 'success') {
      progressEnd()
    } else if (query.status === 'error') {
      handleError(query.error, 'Error getting stories')
      progressEnd()
    }
  }, [handleError, progressEnd, progressStart, query.error, query.status])

  return {
    stories: query.data
  }
}

export { useStories }
