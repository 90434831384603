import React from 'react'
import { propTypes } from '../../helpers'
import { Image } from 'react-bootstrap'

const VisualizationImage = ({ visualization }) => (
  <>
    <Image
      fluid
      src={`data:${visualization.contentType};base64,${visualization.content}`}
      alt={visualization.description}
      longdesc='#image-longdesc'
    />
    <div id='image-longdesc'>{visualization.longDescription}</div>
  </>
)

VisualizationImage.propTypes = {
  visualization: propTypes.visualizationPropType.isRequired
}

export { VisualizationImage }
