import { useStore } from '../store'

const useErrorHandler = () => {
  const setError = useStore((state) => state.setError)

  const handleError = (error, message) => {
    if (error?.message === 'Failed to fetch') {
      setError('Unable to connect to services')
    } else {
      setError(error.message || message)
    }
  }

  return { handleError }
}

export { useErrorHandler }
