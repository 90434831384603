import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

const SkipLink = styled(Button)`
  position: absolute;
  top: 20px;
  left: -9999px;
  z-index: 1500;
  &:focus {
    left: 50%;
    transform: translateX(-50%);
  }
`

const SkipToMainContent = () => (
  <SkipLink variant='link' href='#main-content'>
    Skip to main content
  </SkipLink>
)
export { SkipToMainContent }
