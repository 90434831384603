import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'
import { BaseFileConnectors } from 'react-keyed-file-browser'
import { DropTarget } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import styled from 'styled-components'

const StyledTh = styled.th`
  cursor: pointer;
  line-height: 1.5;
  span {
    color: darkgray;
  }
`
/**
 * Adapted from react-keyed-file-browser/src/headers/table.js
 */
const RawHeaderRenderer = (props) => {
  const { icons } = props.browserProps
  const { onSort } = props
  const [sortBy, setSortBy] = useState('')
  const [order, setOrder] = useState('')

  useEffect(() => onSort(sortBy, order), [sortBy, order])

  const handleSort = (col) => {
    if (sortBy === col) {
      if (order === 'asc') {
        setOrder('desc')
      } else {
        setSortBy('')
        setOrder('')
      }
    } else {
      setSortBy(col)
      setOrder('asc')
    }
  }

  const Col = ({ name, children }) => {
    return (
      <StyledTh
        className={name}
        tabIndex={0}
        onClick={() => handleSort(name)}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault()
            // focusRef.current = event.target
            handleSort(name)
          }
        }}
      >
        {children}{' '}
        {sortBy === name ? (
          order === 'asc' ? (
            icons.CaretUp
          ) : (
            icons.CaretDown
          )
        ) : (
          <span>{icons.Sort}</span>
        )}
      </StyledTh>
    )
  }

  const Header = (
    <tr
      className={ClassNames('folder', {
        dragover: props.isOver,
        selected: props.isSelected
      })}
    >
      <Col name='key'>File</Col>
      <Col name='size'>Size</Col>
      <Col name='modified'>Last Modified</Col>
    </tr>
  )

  if (
    typeof props.browserProps.createFiles === 'function' ||
    typeof props.browserProps.moveFile === 'function' ||
    typeof props.browserProps.moveFolder === 'function'
  ) {
    return props.connectDropTarget(Header)
  } else {
    return Header
  }
}

RawHeaderRenderer.propTypes = {
  select: PropTypes.func,
  fileKey: PropTypes.string,

  connectDropTarget: PropTypes.func,
  isOver: PropTypes.bool,
  isSelected: PropTypes.func,

  browserProps: PropTypes.shape({
    createFiles: PropTypes.func,
    moveFolder: PropTypes.func,
    moveFile: PropTypes.func
  })
}

const HeaderRenderer = DropTarget(
  ['file', 'folder', NativeTypes.FILE],
  BaseFileConnectors.targetSource,
  BaseFileConnectors.targetCollect
)(RawHeaderRenderer)

export { HeaderRenderer, RawHeaderRenderer }
