import React, { useState } from 'react'
import PropTypes from 'prop-types'
import KeyedFileBrowser from 'react-keyed-file-browser'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faCaretUp,
  faCircleNotch,
  faFile,
  faFileDownload,
  faFileImage,
  faFilePdf,
  faFileUpload,
  faFolder,
  faFolderOpen,
  faICursor,
  faSort,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as CopyLink } from './assets/images/copy_link.svg'
import {
  ActionRenderer,
  FileRenderer,
  FilterRenderer,
  FolderRenderer,
  HeaderRenderer
} from './renderers'
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css'

const Icon = styled(FontAwesomeIcon)`
  padding-right: 3px;
`

const FileBrowser = ({
  editable,
  files,
  onCreate,
  onRename,
  onDelete,
  onDownload,
  ...other
}) => {
  const [sort, setSort] = useState()
  const [order, setOrder] = useState()

  const onCreateFolder = (path) => onCreate(null, path)

  const onSort = (sortBy, orderBy) => {
    setSort(sortBy)
    setOrder(orderBy)
  }

  const sorter = (files) => {
    const lower = (arg) => (typeof arg === 'string' ? arg.toLowerCase() : arg)
    const recursiveSort = (a, b) => {
      a.children && (a.children = [...a.children].sort(recursiveSort))
      b.children && (b.children = [...b.children].sort(recursiveSort))
      if (order === 'asc') {
        return lower(a[sort]) > lower(b[sort]) ? 1 : -1
      } else {
        return lower(a[sort]) <= lower(b[sort]) ? 1 : -1
      }
    }

    if (!sort) {
      return files
    } else {
      return [...files].sort(recursiveSort)
    }
  }

  return (
    <KeyedFileBrowser
      files={files}
      icons={{
        CaretDown: <FontAwesomeIcon icon={faCaretDown} size='lg' />,
        CaretUp: <FontAwesomeIcon icon={faCaretUp} size='lg' />,
        Sort: <FontAwesomeIcon icon={faSort} size='lg' />,
        File: <Icon icon={faFile} size='lg' />,
        Image: <Icon icon={faFileImage} size='lg' />,
        PDF: <Icon icon={faFilePdf} size='lg' />,
        Rename: <FontAwesomeIcon icon={faICursor} size='lg' />,
        Folder: <Icon icon={faFolder} size='lg' />,
        FolderOpen: <Icon icon={faFolderOpen} size='lg' />,
        Delete: <FontAwesomeIcon icon={faTrash} size='lg' />,
        Loading: <Icon icon={faCircleNotch} spin size='lg' />,
        Download: <FontAwesomeIcon icon={faFileDownload} size='lg' />,
        CopyLink: (
          <CopyLink aria-hidden className='svg-inline--fa fa-w-14 fa-lg' />
        ),
        Upload: <Icon icon={faFileUpload} size='lg' />
      }}
      actionRenderer={ActionRenderer}
      fileRenderer={FileRenderer}
      filterRenderer={FilterRenderer}
      folderRenderer={FolderRenderer}
      headerRenderer={HeaderRenderer}
      headerRendererProps={{ onSort }}
      sort={sorter}
      onCreateFolder={editable && onCreateFolder}
      onCreateFiles={editable && onCreate}
      onMoveFolder={editable && onRename}
      onMoveFile={editable && onRename}
      onRenameFolder={editable && onRename}
      onRenameFile={editable && onRename}
      onDeleteFolder={editable && onDelete}
      onDeleteFile={editable && onDelete}
      onDownloadFile={onDownload}
      {...other}
    />
  )
}

FileBrowser.propTypes = {
  editable: PropTypes.bool,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
      modified: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.instanceOf(Date)
      ]),
      size: PropTypes.number
    })
  ).isRequired,
  onCreate: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired
}

export { FileBrowser }
