import styled from 'styled-components'

const ContentPanel = styled.div`
  border-radius: 1.6rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 15px;
  margin-bottom: 1.5rem;
  padding: 1.25rem;
`

export { ContentPanel }
