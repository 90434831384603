import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import {
  AffiliationForm,
  ContentPanel,
  DropdownAccordion,
  Toolbar
} from '../../components'
import styled from 'styled-components'
import { useSaveAffiliationMutation, useUser } from '../../hooks'
import { useHistory } from 'react-router-dom'
import { AffiliationsList } from './AffiliationsList'
import { Helmet } from 'react-helmet'
import { useStore } from '../../store'

const CREATE = { id: 1, name: 'Create a New Group' }
const MANAGE = { id: 2, name: 'Manage Current Groups' }
const affiliationsManagementOptions = [CREATE, MANAGE]

const Title = styled.div`
  font-size: 28px;
  padding-bottom: 0.5rem;
`

const MasterAdminPage = () => {
  const [selected, setSelected] = useState(affiliationsManagementOptions[0])
  const { user } = useUser()
  const history = useHistory()
  user?.authority === 'ROLE_ADMIN' || history.push('/')
  const applicationName = useStore((state) => state.applicationName)

  const { mutate: saveAffiliation } = useSaveAffiliationMutation()

  const handleSubmit = (data) =>
    saveAffiliation(
      { data },
      {
        onSuccess: () => {
          setSelected(MANAGE)
        }
      }
    )

  return (
    <Container className='resizing-container'>
      <Helmet>
        <title>Groups Management - {applicationName}</title>
      </Helmet>
      <Toolbar title='Groups Management'>
        <DropdownAccordion
          id='affiliationsManagementSelect'
          title='Select'
          options={affiliationsManagementOptions}
          selectedOption={selected}
          onOptionSelect={setSelected}
          variant='link'
        />
      </Toolbar>
      <ContentPanel className='group-panel'>
        <Title>{selected.name}</Title>
        {selected === CREATE ? (
          <AffiliationForm onSubmit={handleSubmit} />
        ) : (
          <AffiliationsList />
        )}
      </ContentPanel>
    </Container>
  )
}

export { MasterAdminPage }
