import decode from 'jwt-decode'

const setTokenResponse = (tokenResponse) => {
  if (tokenResponse) {
    sessionStorage.setItem('AUTH-TOKEN-RESPONSE', JSON.stringify(tokenResponse))
  } else {
    sessionStorage.removeItem('AUTH-TOKEN-RESPONSE')
  }
}

const getTokenResponse = () => {
  return JSON.parse(sessionStorage.getItem('AUTH-TOKEN-RESPONSE'))
}

const getAccessToken = () => {
  const tokenResponse = getTokenResponse()
  return tokenResponse ? tokenResponse.accessToken : null
}

const getRefreshToken = () => {
  const tokenResponse = getTokenResponse()
  return tokenResponse ? tokenResponse.refreshToken : null
}

const isAccessTokenValid = () => {
  try {
    const decoded = decode(getAccessToken())
    const now = new Date().getTime() / 1000
    return decoded.exp > now
  } catch (error) {
    return false
  }
}

const isRefreshTokenValid = () => {
  try {
    const decoded = decode(getRefreshToken())
    const now = new Date().getTime() / 1000
    return decoded.exp > now
  } catch (error) {
    return false
  }
}

export {
  getAccessToken,
  getRefreshToken,
  isAccessTokenValid,
  isRefreshTokenValid,
  setTokenResponse
}
