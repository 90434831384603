import React, { forwardRef, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import requiredIf from 'react-required-if'
import {
  Nav,
  Navbar,
  NavDropdown,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import { Icon } from '../icon'
import {
  faQuestionCircle,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

const NavbarStyled = styled(Navbar)`
  top: 10px;
  .dropdown-menu {
    max-height: 50vh;
    overflow-y: auto;
  }
`
const NavHelp = styled(Nav)`
  position: absolute;
  top: 8px;
  right: ${(props) => `${props.right}px`};
  @media (max-width: 991px) {
    right: 18px;
  }
`
const RightDropdown = styled(NavDropdown)`
  .dropdown-menu {
    left: auto;
    right: 0;
  }
`

const Options = ({ options, selectedOption, onOptionSelect, disabled }) => {
  const onSelect = (eventKey) =>
    onOptionSelect(options.find((it) => it.id === Number(eventKey)))

  return options.length > 1 ? (
    <Nav onSelect={onSelect} title='Select Option'>
      <NavDropdown
        disabled={disabled}
        id='select-option'
        title={selectedOption.name}
      >
        {options.map((item) => (
          <NavDropdown.Item
            key={item.id}
            eventKey={item.id}
            active={item.id === selectedOption.id}
          >
            {item.name}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </Nav>
  ) : options.length === 1 ? (
    <Navbar.Text>{options[0].name}</Navbar.Text>
  ) : null
}

const Links = ({ links, disabled }) => (
  <Nav id='nav-links'>
    {links.map((link) => (
      <Nav.Link
        key={link.url}
        href={link.url}
        disabled={disabled}
        {...link.props}
      >
        {link.name}
      </Nav.Link>
    ))}
  </Nav>
)

const UserMenu = forwardRef(
  ({ userName, userOptions, onUserOptionSelect, loggedIn, disabled }, ref) => {
    const onSelect = (eventKey) =>
      onUserOptionSelect(userOptions.find((it) => it.id === Number(eventKey)))

    return loggedIn ? (
      <OverlayTrigger
        placement='bottom'
        overlay={<Tooltip id='user-tooltip'>Account</Tooltip>}
      >
        <Nav onSelect={onSelect} ref={ref} className='ml-auto'>
          <RightDropdown
            disabled={disabled}
            title={
              <span>
                <Icon icon={faUserCircle} size='lg' />
                <span>{userName || null}</span>
              </span>
            }
            id='nav-dropdown-user'
          >
            {userOptions.map((item) => (
              <NavDropdown.Item key={item.id} eventKey={item.id} active={false}>
                {item.name}
              </NavDropdown.Item>
            ))}
          </RightDropdown>
        </Nav>
      </OverlayTrigger>
    ) : null
  }
)

const HelpMenu = ({ helpOptions, onHelpOptionSelect, right, disabled }) => {
  const onSelect = (eventKey) =>
    onHelpOptionSelect(helpOptions.find((it) => it.id === Number(eventKey)))

  return (
    <NavHelp right={right}>
      <OverlayTrigger
        placement='bottom'
        overlay={<Tooltip id='help-tooltip'>Help</Tooltip>}
      >
        <Nav onSelect={onSelect} className='ml-auto'>
          <RightDropdown
            disabled={disabled}
            title={
              <span>
                <Icon icon={faQuestionCircle} size='lg' />
                <span className='sr-only'>Help Menu</span>
              </span>
            }
            id='nav-dropdown-help'
          >
            {helpOptions.map((item) => (
              <NavDropdown.Item key={item.id} eventKey={item.id} active={false}>
                {item.name}
              </NavDropdown.Item>
            ))}
          </RightDropdown>
        </Nav>
      </OverlayTrigger>
    </NavHelp>
  )
}

const Header = ({
  options,
  selectedOption,
  onOptionSelect,
  links,
  disabled,
  loggedIn,
  userName,
  userOptions,
  onUserOptionSelect,
  helpOptions,
  onHelpOptionSelect,
  ...other
}) => {
  const [width, setWidth] = useState()
  const [expanded, setExpanded] = useState(false)

  const measuredRef = useCallback(
    (node) => {
      if (node !== null) {
        setWidth(node.getBoundingClientRect().width + 10)
      }
    },
    [userName] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <header>
      <NavbarStyled {...other} defaultExpanded={false} onToggle={setExpanded}>
        <Navbar.Toggle aria-expanded={expanded} />
        <Navbar.Collapse id='basic-navbar-nav'>
          {options?.length ? (
            <Options
              options={options}
              selectedOption={selectedOption}
              onOptionSelect={onOptionSelect}
              disabled={disabled}
            />
          ) : null}
          {links?.length ? <Links links={links} disabled={disabled} /> : null}
          {loggedIn ? (
            <UserMenu
              userName={userName}
              userOptions={userOptions}
              onUserOptionSelect={onUserOptionSelect}
              loggedIn={loggedIn}
              disabled={disabled}
              ref={measuredRef}
            />
          ) : null}
        </Navbar.Collapse>
        {helpOptions?.length ? (
          <HelpMenu
            helpOptions={helpOptions}
            onHelpOptionSelect={onHelpOptionSelect}
            right={loggedIn ? width : 10}
            disabled={disabled}
          />
        ) : null}
      </NavbarStyled>
    </header>
  )
}

Header.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  selectedOption: requiredIf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }),
    (props) => props.options && props.options.length > 1
  ),
  onOptionSelect: requiredIf(
    PropTypes.func,
    (props) => props.options && props.options.length > 1
  ),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      props: PropTypes.object
    })
  ),
  disabled: PropTypes.bool,
  loggedIn: PropTypes.bool,
  userName: PropTypes.string,
  userOptions: requiredIf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    ),
    (props) => props.loggedIn
  ),
  onUserOptionSelect: requiredIf(PropTypes.func, (props) => props.loggedIn),
  helpOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  onHelpOptionSelect: requiredIf(PropTypes.func, (props) => props.helpOptions)
}

export { Header }
