import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { format, parseISO } from 'date-fns'
import { Button, Form, Table } from 'react-bootstrap'
import { useSortBy, useTable } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faCaretUp,
  faEye,
  faEyeSlash,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import ReactPaginate from 'react-paginate'
import { Icon } from '../icon'

const AffiliationsTable = ({
  affiliations,
  maxOrderNumber,
  page,
  pageCount,
  onPageChange,
  sort,
  onSort,
  onUpdate,
  onDelete
}) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Group Order',
        accessor: 'orderNumber',
        Cell: ({ row, value }) => (
          <Form.Control
            aria-label='orderNumber'
            as='select'
            value={value}
            onChange={(e) =>
              onUpdate(row.original.id, 'orderNumber', e.target.value)
            }
          >
            {Array(maxOrderNumber)
              .fill()
              .map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  #{i + 1}
                </option>
              ))}
          </Form.Control>
        )
      },
      {
        Header: 'Group Name',
        accessor: 'name',
        Cell: ({ row, value }) => {
          const [showConfirm, setShowConfirm] = useState(false)
          const [newValue, setNewValue] = useState(value)
          return (
            <>
              <Form.Control
                aria-label='name'
                type='text'
                value={newValue}
                onChange={(e) => {
                  setShowConfirm(e.target.value !== value)
                  setNewValue(e.target.value)
                }}
              />
              {showConfirm ? (
                <div className='pt-2'>
                  <Button
                    variant='secondary'
                    onClick={() => onUpdate(row.original.id, 'name', newValue)}
                  >
                    Confirm
                  </Button>{' '}
                  <Button
                    variant='outline-dark'
                    onClick={() => {
                      setNewValue(value)
                      setShowConfirm(false)
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
            </>
          )
        }
      },
      {
        Header: 'Last Modified',
        accessor: 'lastUpdated',
        Cell: ({ value }) => {
          try {
            return value ? format(parseISO(value), 'Pp') : null
          } catch (e) {
            console.error('Error parsing lastUpdated', value, e.stack)
            return null
          }
        }
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
        Cell: ({ value }) => {
          try {
            return value ? format(parseISO(value), 'P') : null
          } catch (e) {
            console.error('Error parsing dateCreated', value, e.stack)
            return null
          }
        }
      },
      {
        Header: 'Actions',
        disableSortBy: true,
        accessor: (row) => row,
        Cell: ({ value }) => {
          const { id, hidden } = value
          return (
            <div>
              <Button
                variant='link'
                className='text-muted'
                onClick={() => onUpdate(id, 'hidden', !hidden)}
              >
                <Icon icon={value ? faEye : faEyeSlash} size='lg' />
                {hidden ? 'Unhide' : 'Hide'}
              </Button>
              |
              <Button
                variant='link'
                className='text-muted'
                onClick={() => onDelete(id)}
              >
                <Icon icon={faTrash} size='lg' />
                Delete
              </Button>
            </div>
          )
        }
      }
    ],
    [onDelete, onUpdate, maxOrderNumber]
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
    setSortBy
  } = useTable(
    {
      columns,
      data: affiliations,
      initialState: { sortBy: sort },
      manualSortBy: true
    },
    useSortBy
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setSortBy(sort), [sort])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onSort(sortBy), [sortBy])

  return (
    <>
      <Table
        aria-label='affiliations admin table'
        responsive
        bordered
        hover
        striped
        size='sm'
        className='affiliations-admin-table'
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: undefined })
                  )}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size='lg'
                        className='ml-1'
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size='lg'
                        className='ml-1'
                      />
                    )
                  ) : (
                    ''
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        previousLabel='<'
        nextLabel='>'
        breakClassName='page-item'
        breakLinkClassName='page-link'
        onPageChange={onPageChange}
        forcePage={page}
        containerClassName='pagination justify-content-center'
        pageClassName='page-item'
        pageLinkClassName='page-link'
        activeClassName='active'
        previousClassName='page-item'
        nextClassName='page-item'
        previousLinkClassName='page-link'
        nextLinkClassName='page-link'
      />
    </>
  )
}

AffiliationsTable.propTypes = {
  affiliations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      orderNumber: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      lastUpdated: PropTypes.string.isRequired,
      dateCreated: PropTypes.string.isRequired,
      hidden: PropTypes.bool.isRequired
    })
  ).isRequired,
  maxOrderNumber: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      desc: PropTypes.bool
    }).isRequired
  ).isRequired,
  onSort: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export { AffiliationsTable }
