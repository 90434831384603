import React, { useEffect } from 'react'
import { ContentPanel } from '../container'
import { env } from '../../helpers'
import { useStore } from '../../store'

const ErrorFallback = () => {
  const setError = useStore((state) => state.setError)

  useEffect(() => setError('An unexpected error has occurred'), [setError])

  return (
    <>
      <ContentPanel className='error-panel'>
        <p>Please reload/refresh this page in your browser.</p>
        <p>
          If the error persists,
          <a
            href={env.supportUrl}
            target='_blank'
            rel='noopener noreferrer'
            className='px-1'
          >
            Contact Us
          </a>
          for assistance
        </p>
      </ContentPanel>
    </>
  )
}

export { ErrorFallback }
