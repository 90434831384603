import { useMutation, useQueryClient } from 'react-query'
import { useAuth } from 'react-oauth2'
import { env } from '../../helpers'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useUpdateAffiliationMutation = () => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const setMessage = useStore((state) => state.setMessage)
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)
  const queryClient = useQueryClient()

  const updateAffiliation = async (id, property, value) => {
    const url = encodeURI(`${env.apiUrl}/affiliations/${id}`)
    const response = await securedFetch(url, {
      method: 'PATCH',
      body: JSON.stringify({ [property]: value })
    })
    await handleResponse(response)
  }

  return useMutation(
    ({ id, property, value }) => updateAffiliation(id, property, value),
    {
      onMutate: progressStart,
      onError: (error) => handleError(error, 'Error updating group'),
      onSuccess: () => {
        setMessage('Updated group')
        queryClient.invalidateQueries('affiliations')
        queryClient.invalidateQueries('admin-affiliations')
      },
      onSettled: progressEnd
    }
  )
}

export { useUpdateAffiliationMutation }
