import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const FooterStyled = styled.footer`
  padding: .5rem;
  font-size: .8rem;
  p {
    margin-bottom: .25rem;
  }
  .btn-link {
    font-size: .8rem;
    padding 0;
  }
`

const Copyright = ({ startYear }) => {
  const current = new Date().getFullYear()
  const dates =
    startYear && startYear < current ? `${startYear}-${current} ` : current
  return (
    <span className='copyright'>{`© ${dates} The Center for Clinical and Translational Science (CCTS) at The Ohio State University`}</span>
  )
}

Copyright.propTypes = {
  startYear: PropTypes.number
}

const Link = ({ url, children }) => (
  <a href={url} className='btn-link' target='_blank' rel='noopener noreferrer'>
    {children}
  </a>
)

Link.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

const Footer = ({ children, className }) => (
  <FooterStyled className={className}>{children}</FooterStyled>
)

Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export { Copyright, Footer, Link }
