import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 1.6rem;
    padding: 1rem;
  }
  .modal-header {
    border-bottom: none;
    .modal-title {
      line-height: 1.25;
      font-size: 1.25rem;
    }
  }
  .modal-footer {
    border-top: none;
  }
`

const ConfirmModal = ({ show, title, body, onConfirm, onCancel }) => (
  <StyledModal
    show={show}
    onHide={onCancel}
    aria-labelledby='confirmTitle'
    aria-describedby='confirmDescription'
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id='confirmTitle'>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body id='confirmDescription' className='py-0'>
      {body}
    </Modal.Body>
    <Modal.Footer>
      <Button variant='secondary' onClick={onConfirm}>
        Confirm
      </Button>
      <Button variant='outline-dark' onClick={onCancel}>
        Cancel
      </Button>
    </Modal.Footer>
  </StyledModal>
)

ConfirmModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export { ConfirmModal }
