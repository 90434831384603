import React, { useEffect } from 'react'
import { propTypes } from '../../helpers'
import { FileBrowser } from 'react-file-browser'
import {
  useDeleteDocumentMutation,
  useGetDocumentMutation,
  useRenameDocumentMutation,
  useSaveDocumentsMutation
} from '../../hooks'
import fileDownload from 'js-file-download'
import { PERMISSION, useStore } from '../../store'

const DetailRenderer = ({ close, file }) => {
  useEffect(() => {
    window.open(file.url, '_blank', 'noopener,noreferrer')
    close()
  })
  return null
}

const DocumentBrowser = ({ documents }) => {
  const permissions = useStore((state) => state.permissions)
  const setMessage = useStore((state) => state.setMessage)
  const { mutate: deleteDocument } = useDeleteDocumentMutation()
  const { mutate: getDocument } = useGetDocumentMutation()
  const { mutate: renameDocument } = useRenameDocumentMutation()
  const { mutate: saveDocuments } = useSaveDocumentsMutation()

  const handleCreate = (files, path) => saveDocuments({ files, path })
  const handleRename = (oldPath, newPath) => {
    const file = documents.find((doc) => doc.path === oldPath)
    renameDocument({ documentId: file.id, path: newPath })
  }
  const handleDelete = (path) => {
    const file = documents.find((doc) => doc.path === path)
    deleteDocument({ documentId: file.id })
  }
  const handleDownload = (path) => {
    const file = documents.find((doc) => doc.path === path)
    getDocument(
      { file },
      {
        onMutate: () => {
          setMessage('Downloading...')
        },
        onSuccess: (data, { file }) => {
          fileDownload(data, file.name)
          setMessage('Document was downloaded')
        }
      }
    )
  }

  return (
    <FileBrowser
      editable={permissions.includes(PERMISSION.EDIT)}
      files={documents}
      onCreate={handleCreate}
      onRename={handleRename}
      onDelete={handleDelete}
      onDownload={handleDownload}
      detailRenderer={DetailRenderer}
    />
  )
}

DocumentBrowser.propTypes = {
  documents: propTypes.documentArrayPropType
}

export { DocumentBrowser }
