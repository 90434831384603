import { Col, Jumbotron } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import PropTypes from 'prop-types'

const SearchSplash = ({ text }) => (
  <Jumbotron className='text-center text-muted mb-0'>
    <Col lg={{ span: 4, offset: 4 }}>
      <p>
        <FontAwesomeIcon icon={faSearch} size='3x' />
      </p>
      <p className='font-weight-bold'>{text}</p>
    </Col>
  </Jumbotron>
)

SearchSplash.propTypes = {
  text: PropTypes.string.isRequired
}

export { SearchSplash }
