import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { PanelGrid } from './PanelGrid'
import { Editor, rawToState } from 'react-editor'
import { Link } from 'react-router-dom'

const StoryGrid = ({ stories, pathname, draggable, onDragEnd }) => (
  <PanelGrid
    className='story-grid'
    items={stories}
    renderItem={(story) => {
      const onKeyDown = (event) => event.stopPropagation()
      return (
        <div onKeyDown={onKeyDown}>
          <Editor readOnly editorState={rawToState(story.content)} />
          <Button
            as={Link}
            to={pathname + '/' + story.id}
            variant='outline-secondary'
          >
            Read More
          </Button>
        </div>
      )
    }}
    draggable={draggable}
    onDragEnd={onDragEnd}
  />
)

StoryGrid.propTypes = {
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired
    })
  ),
  pathname: PropTypes.string.isRequired,
  draggable: PropTypes.bool,
  onDragEnd: PropTypes.func
}

export { StoryGrid }
