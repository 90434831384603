import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from 'react-oauth2'
import { env } from '../../helpers'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useAdminCategories = () => {
  const { securedFetch } = useAuth()
  const selectedAffiliation = useStore((state) => state.selectedAffiliation)
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()

  const getAdminCategories = async () => {
    const url = encodeURI(
      `${env.apiUrl}/affiliations/${selectedAffiliation.id}/categories`
    )
    const response = await securedFetch(url)
    await handleResponse(response)
    return response.json()
  }

  const query = useQuery(
    ['admin-categories', selectedAffiliation?.id],
    getAdminCategories,
    {
      enabled: !!selectedAffiliation
    }
  )

  useEffect(() => {
    query.status === 'error' &&
      handleError(query.error, 'Error getting categories')
  }, [handleError, query.error, query.status])

  return {
    categories: query.data
  }
}

export { useAdminCategories }
