import React, { Fragment, useEffect, useState } from 'react'
import { Accordion, DropdownButton, Dropdown } from 'react-bootstrap'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const AccordionButton = styled(DropdownButton)`
  .dropdown-toggle {
    font-size: 24px;
  }
`

const AccordionCollapse = styled(Accordion.Collapse)`
  .dropdown-item {
    padding-left: 2.5em;
  }
`

const DropdownAccordion = ({
  title,
  options,
  selectedOption,
  onOptionSelect,
  disabled,
  className,
  ...other
}) => {
  const [groups, setGroups] = useState([])

  useEffect(() => {
    setGroups(
      options.reduce((groups, option) => {
        const current = groups[groups.length - 1]
        if (current && current[0].group === option.group) {
          current.push(option)
        } else {
          groups.push([option])
        }
        return groups
      }, [])
    )
  }, [options])

  const handleClick = (event, item) => {
    let parent = event.target.parentNode
    if (parent.classList.contains('collapse')) {
      parent.previousSibling.click()
      parent = parent.parentNode
    }
    parent.parentNode.parentNode.click()
    onOptionSelect(item)
  }

  const handleKeyDown = (e) => {
    if (
      e.key === 'ArrowDown' &&
      e.target.nextSibling &&
      e.target.nextSibling.classList.contains('collapse')
    ) {
      e.target.nextSibling.nextSibling &&
        e.target.nextSibling.nextSibling.focus()
    } else if (
      e.key === 'ArrowUp' &&
      e.target.previousSibling &&
      e.target.previousSibling.classList.contains('collapse')
    ) {
      e.target.previousSibling.previousSibling &&
        e.target.previousSibling.previousSibling.focus()
    }
  }

  const AccordionGroup = ({ items, eventKey }) => (
    <Fragment>
      <Accordion.Toggle
        as='button'
        className='dropdown-toggle dropdown-item'
        eventKey={eventKey}
        onKeyDown={handleKeyDown}
      >
        {items[0].group}
      </Accordion.Toggle>
      <AccordionCollapse eventKey={eventKey}>
        <>
          {items.map((item) => (
            <Dropdown.Item
              as='button'
              key={item.id}
              active={selectedOption && selectedOption.id === item.id}
              onClick={(e) => handleClick(e, item)}
            >
              {item.name}
            </Dropdown.Item>
          ))}
        </>
      </AccordionCollapse>
    </Fragment>
  )

  const DropdownOptions = ({ options, eventKey }) => {
    return options[0].group ? (
      <AccordionGroup items={options} eventKey={eventKey} />
    ) : (
      options.map((item) => (
        <Dropdown.Item
          as='button'
          key={item.id}
          active={selectedOption && selectedOption.id === item.id}
          onClick={(e) => handleClick(e, item)}
          onKeyDown={handleKeyDown}
        >
          {item.name}
        </Dropdown.Item>
      ))
    )
  }

  return (
    <AccordionButton
      title={title}
      className={className}
      disabled={disabled}
      {...other}
    >
      <Accordion>
        {groups.map((group, i) => (
          <DropdownOptions key={i} options={group} eventKey={`${i}`} />
        ))}
      </Accordion>
    </AccordionButton>
  )
}

DropdownAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      group: PropTypes.string,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedOption: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  onOptionSelect: PropTypes.func,
  disabled: PropTypes.bool
}

export { DropdownAccordion }
