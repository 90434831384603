import React from 'react'
import Alert from 'react-bootstrap/Alert'
import { Icon } from '../icon'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { useStore } from '../../store'

const SuccessAlert = () => {
  const message = useStore((state) => state.message)
  const setMessage = useStore((state) => state.setMessage)

  return message ? (
    <Alert variant='success' dismissible onClose={() => setMessage('')}>
      <Icon icon={faThumbsUp} size='lg' />
      {message}
    </Alert>
  ) : null
}

export { SuccessAlert }
