import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'

const RowStyled = styled(Row)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`
const ColStyled = styled(Col)`
  height: 10px;
  border-radius: 5px;
  @media (max-width: 575px) {
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
  }
`

const HeaderCap = ({ className }) => (
  <RowStyled
    className={className}
    title='Click to sroll to the top of the page'
    onClick={() => window.scrollTo(0, 0)}
  >
    <ColStyled
      className='graphicElementGradient'
      xs={0}
      sm={{ span: 4, offset: 8 }}
      md={{ span: 3, offset: 8 }}
    />
  </RowStyled>
)

HeaderCap.propTypes = {
  className: PropTypes.string
}

export { HeaderCap }
