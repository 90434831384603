import { env } from '../../helpers'
import { useMutation, useQueryClient } from 'react-query'
import { useAuth } from 'react-oauth2'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useDeleteStoryMutation = () => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const setMessage = useStore((state) => state.setMessage)
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)
  const queryClient = useQueryClient()

  const deleteStory = async (storyId) => {
    const url = encodeURI(`${env.apiUrl}/stories/${storyId}`)
    const response = await securedFetch(url, { method: 'DELETE' })
    await handleResponse(response)
  }

  return useMutation(({ categoryId, storyId }) => deleteStory(storyId), {
    onMutate: progressStart,
    onError: (error) => handleError(error, 'Error deleting story'),
    onSuccess: (data, { categoryId }) => {
      setMessage('Story was deleted')
      queryClient.invalidateQueries(['stories', categoryId])
    },
    onSettled: progressEnd
  })
}

export { useDeleteStoryMutation }
