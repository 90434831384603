import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, useLocation } from 'react-router-dom'
import { useAuth } from 'react-oauth2'
import { Main } from './Main'
import { useStore } from '../store'

const App = ({
  applicationName,
  categoryRenderer,
  footerRenderer,
  imgSrcLogin,
  imgAltLogin,
  imgSrcLogo,
  imgAltLogo,
  imgSrcTour,
  steps
}) => {
  const { Callback } = useAuth()
  const setApplicationName = useStore((state) => state.setApplicationName)
  const setError = useStore((state) => state.setError)
  const setImgSrcLogin = useStore((state) => state.setImgSrcLogin)
  const setImgAltLogin = useStore((state) => state.setImgAltLogin)
  const setImgSrcLogo = useStore((state) => state.setImgSrcLogo)
  const setImgAltLogo = useStore((state) => state.setImgAltLogo)
  const setImgSrcTour = useStore((state) => state.setImgSrcTour)
  const location = useLocation()

  setApplicationName(applicationName)
  setImgSrcLogin(imgSrcLogin)
  setImgAltLogin(imgAltLogin)
  setImgSrcLogo(imgSrcLogo)
  setImgAltLogo(imgAltLogo)
  setImgSrcTour(imgSrcTour)

  useEffect(() => {
    document.body.tabIndex = -1
    document.body.focus()
  }, [location.pathname])

  const onCallbackError = (error) => {
    console.error('Callback Error', error)
    setError('Authentication Error')
  }

  return (
    <Switch>
      <Route exact path='/callback'>
        <Callback onError={onCallbackError} />
      </Route>
      <Route path='/:affiliationUrlSlug/:categoryUrlSlug?/:id?'>
        <Main {...{ categoryRenderer, footerRenderer, steps }} />
      </Route>
      <Route path='/'>
        <Main {...{ categoryRenderer, footerRenderer, steps }} />
      </Route>
    </Switch>
  )
}

App.propTypes = {
  applicationName: PropTypes.string.isRequired,
  categoryRenderer: PropTypes.elementType.isRequired,
  footerRenderer: PropTypes.elementType.isRequired,
  imgSrcLogin: PropTypes.string.isRequired,
  imgAltLogin: PropTypes.string.isRequired,
  imgSrcLogo: PropTypes.string.isRequired,
  imgAltLogo: PropTypes.string.isRequired,
  imgSrcTour: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  )
}

export { App }
