const getRequest = () => {
  const identifier = window.localStorage.getItem(
    'appauth_current_authorization_request'
  )
  return JSON.parse(
    window.localStorage.getItem(`${identifier}_appauth_authorization_request`)
  )
}

const getCodeVerifier = () => getRequest()?.internal?.code_verifier

export { getCodeVerifier, getRequest }
