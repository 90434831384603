import React, { useEffect, useRef, useState } from 'react'
import { ConfirmPopover, ContentPanel, Toolbar } from '../../components'
import {
  useDeleteDocumentMutation,
  useDocuments,
  useGetDocumentMutation,
  useRenameDocumentMutation
} from '../../hooks'
import { PERMISSION, useStore } from '../../store'
import { useHistory, useParams } from 'react-router-dom'
import { DocumentBrowser } from './DocumentBrowser'
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  FormControl,
  Row
} from 'react-bootstrap'
import {
  faFileDownload,
  faICursor,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fileDownload from 'js-file-download'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

const DocumentTitle = styled.div`
  font-size: 28px;
  padding-bottom: 0.5rem;
  .form-control {
    font-size: 28px;
  }
`

const Document = styled.object`
  height: 100%;
  min-height: 550px;
  width: 100%;
`

const DocumentPage = () => {
  const { affiliationUrlSlug, categoryUrlSlug, id } = useParams()
  const history = useHistory()
  const fileNameRef = useRef(null)
  const [file, setFile] = useState()
  const [data, setData] = useState()
  const [isRenaming, setIsRenaming] = useState()
  const applicationName = useStore((state) => state.applicationName)
  const documentCategory = useStore((state) => state.documentCategory)
  const setError = useStore((state) => state.setError)
  const setMessage = useStore((state) => state.setMessage)
  const permissions = useStore((state) => state.permissions)

  const { documents } = useDocuments()
  const { mutate: deleteDocument } = useDeleteDocumentMutation()
  const { mutate: getDocument } = useGetDocumentMutation()
  const { mutate: renameDocument } = useRenameDocumentMutation()

  useEffect(() => {
    if (documents && id) {
      const file = documents.find((it) => it.id === Number(id))
      if (file) {
        setFile(file)
      } else {
        setError(`Document not found`)
      }
    }
  }, [documents, id, setError])

  useEffect(() => {
    file &&
      getDocument(
        { file },
        {
          onMutate: () => setMessage('Retrieving document...'),
          onSuccess: setData
        }
      )
  }, [file, getDocument, setMessage])

  useEffect(() => {
    if (isRenaming) {
      fileNameRef.current.setSelectionRange(0, file.name.lastIndexOf('.'))
      fileNameRef.current.focus()
    }
  }, [file, isRenaming])

  const handleRename = () => setIsRenaming(true)
  const handleRenameBlur = () => setIsRenaming(false)
  const handleRenameKeyPress = (event) => {
    if (event.charCode === 13) {
      const newPath = file.path.replace(file.name, event.target.value)
      renameDocument(
        { documentId: file.id, path: newPath },
        { onSuccess: () => setIsRenaming(false) }
      )
      setIsRenaming(false)
    }
  }

  const handleDelete = () => {
    deleteDocument(
      { documentId: id },
      {
        onSuccess: () => {
          history.push(`/${affiliationUrlSlug}/${categoryUrlSlug}`)
          setMessage('Document was deleted.')
        }
      }
    )
  }
  const handleDownload = () => {
    fileDownload(data, file.name)
    setMessage('Document was downloaded')
  }

  return (
    <Container className='resizing-container'>
      <Helmet>
        <title>
          {documentCategory.name} - {applicationName}
        </title>
      </Helmet>
      {id ? (
        file && data ? (
          <>
            <Toolbar title={documentCategory.name}>
              <ButtonGroup>
                {permissions.includes(PERMISSION.EDIT) && (
                  <>
                    <Button
                      onClick={handleRename}
                      aria-label='Rename'
                      title='Rename'
                      variant='link'
                    >
                      Rename <FontAwesomeIcon icon={faICursor} size='sm' />
                    </Button>
                    <ConfirmPopover id='delete-story' onConfirm={handleDelete}>
                      <Button
                        aria-label='Delete'
                        title='Delete Story'
                        variant='link'
                      >
                        Delete <FontAwesomeIcon icon={faTrash} size='sm' />
                      </Button>
                    </ConfirmPopover>
                  </>
                )}
                <Button
                  onClick={handleDownload}
                  aria-label='Download'
                  title='Download'
                  variant='link'
                >
                  Download <FontAwesomeIcon icon={faFileDownload} size='sm' />
                </Button>
              </ButtonGroup>
            </Toolbar>
            <DocumentTitle>
              {isRenaming ? (
                <Row>
                  <Col lg={8}>
                    <FormControl
                      ref={fileNameRef}
                      aria-label='fileName'
                      defaultValue={file.name}
                      onBlur={handleRenameBlur}
                      onKeyPress={handleRenameKeyPress}
                    />
                  </Col>
                </Row>
              ) : (
                file.name
              )}
            </DocumentTitle>
            <Document
              role='document'
              name={file.name}
              title={file.name}
              type={file.contentType}
              data={URL.createObjectURL(data)}
            >
              <p>
                The preview is not available for this file type. To view the
                document, download and open.
              </p>
            </Document>
          </>
        ) : null
      ) : documents ? (
        <>
          <Toolbar title={documentCategory.name} />
          <ContentPanel className='document-panel'>
            <DocumentBrowser documents={documents} />
          </ContentPanel>
        </>
      ) : null}
    </Container>
  )
}

export { DocumentPage }
