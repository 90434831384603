import PropTypes from 'prop-types'
import React from 'react'

const FilterRenderer = ({ value, updateFilter }) => (
  <input
    type='search'
    aria-label='Filter files'
    placeholder='Filter files'
    value={value}
    onChange={(e) => updateFilter(e.target.value)}
  />
)

FilterRenderer.propTypes = {
  value: PropTypes.string.isRequired,
  updateFilter: PropTypes.func
}

export { FilterRenderer }
