import React from 'react'
import { propTypes } from '../../helpers'
import { VisualizationImage } from './VisualizationImage'
import { VisualizationPdf } from './VisualizationPdf'
import { VisualizationTableau } from './VisualizationTableau'
import { useStore } from '../../store'

const VisualizationFactory = ({ visualization }) => {
  const setError = useStore((state) => state.setError)
  switch (visualization.contentType) {
    case 'image/bmp':
    case 'image/gif':
    case 'image/jpeg':
    case 'image/png':
    case 'image/tiff':
      return <VisualizationImage visualization={visualization} />
    case 'application/pdf':
      return <VisualizationPdf visualization={visualization} />
    case 'application/tableau':
      return <VisualizationTableau visualization={visualization} />
    default:
      setError(
        `The contentType (${visualization.contentType}) is not supported`
      )
      return null
  }
}

VisualizationFactory.propTypes = {
  visualization: propTypes.visualizationPropType.isRequired
}

export { VisualizationFactory }
