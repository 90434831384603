import { env } from '../../helpers'
import { useMutation, useQueryClient } from 'react-query'
import { useAuth } from 'react-oauth2'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useSaveStoryMutation = () => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const setMessage = useStore((state) => state.setMessage)
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)
  const queryClient = useQueryClient()

  const saveStory = async (categoryId, storyId, content) => {
    const url = encodeURI(
      storyId
        ? `${env.apiUrl}/stories/${storyId}`
        : `${env.apiUrl}/categories/${categoryId}/stories`
    )
    const response = await securedFetch(url, {
      method: storyId ? 'PUT' : 'POST',
      body: JSON.stringify({ content: content })
    })
    await handleResponse(response)
    return response.json()
  }

  return useMutation(
    ({ categoryId, storyId, content }) =>
      saveStory(categoryId, storyId, content),
    {
      onMutate: progressStart,
      onError: (error) => handleError(error, 'Error publishing story'),
      onSuccess: (data, variables) => {
        setMessage('Story was published')
        if (!variables.storyId) {
          queryClient.setQueryData(
            ['stories', variables.categoryId],
            (stories) => [...stories, data]
          )
        }
        queryClient.invalidateQueries(['stories', variables.categoryId])
      },
      onSettled: progressEnd
    }
  )
}

export { useSaveStoryMutation }
