import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from 'react-oauth2'
import { env } from '../../helpers'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useAffiliations = () => {
  const { loggedIn, securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()

  const getAffiliations = async () => {
    const fetchFn = loggedIn ? securedFetch : fetch
    const url = encodeURI(
      loggedIn
        ? `${env.apiUrl}/userAffiliations`
        : `${env.publicApiUrl}/affiliations`
    )
    const response = await fetchFn(url)
    await handleResponse(response)
    return response.json()
  }

  const query = useQuery(['affiliations', loggedIn], getAffiliations)

  useEffect(() => {
    query.status === 'error' &&
      handleError(query.error, 'Error getting affiliations')
  }, [handleError, query.error, query.status])

  return {
    affiliations: query.data
  }
}

export { useAffiliations }
