import React from 'react'
import PropTypes from 'prop-types'
import { propTypes } from '../helpers'
import {
  AdminPage,
  CalendarPage,
  DocumentPage,
  StoryPage,
  VisualizationPage
} from '../pages'

const PageFactory = ({ category, categoryRenderer: CategoryPage }) => {
  switch (category.categoryType) {
    case 'ADMIN':
      return <AdminPage category={category} />
    case 'CALENDAR':
      return <CalendarPage category={category} />
    case 'DOCUMENT':
      return <DocumentPage category={category} />
    case 'STORY':
      return <StoryPage category={category} />
    case 'VISUALIZATION':
      return <VisualizationPage category={category} />
    default:
      return <CategoryPage category={category} />
  }
}

PageFactory.propTypes = {
  category: propTypes.categoryPropType,
  categoryRenderer: PropTypes.elementType.isRequired
}

export { PageFactory }
