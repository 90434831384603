import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Form } from 'react-bootstrap'

const TourCard = ({
  index,
  step,
  isLastStep,
  backProps,
  primaryProps,
  closeProps,
  tooltipProps,
  imgSrcTour,
  showDisableTour,
  disableTour,
  onDisableTour
}) => (
  <Card
    className='border-0'
    style={{ width: '30rem', borderRadius: '15px', overflow: 'hidden' }}
    {...tooltipProps}
  >
    {imgSrcTour && step.placement === 'center' && (
      <Card.Img variant='top' src={imgSrcTour} alt='' />
    )}
    <Card.Body>
      {step.title && (
        <Card.Title>
          <button
            type='button'
            className='close'
            aria-label='Close'
            {...closeProps}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
          {step.title}
        </Card.Title>
      )}
      {step.placement !== 'center' && <hr />}
      <Card.Text className='mb-3'>{step.content}</Card.Text>
      {showDisableTour && step.placement === 'center' && (
        <>
          <Form.Check
            id='disableTour'
            type='checkbox'
            className='mb-3'
            label="Don't show me again"
            checked={disableTour}
            onChange={(e) => onDisableTour(e.target.checked)}
            readOnly
          />
          <p className='font-italic small'>
            Checking the box will block page tooltips from showing up on your
            screen automatically.
          </p>
        </>
      )}
      <div className='d-flex justify-content-end'>
        <Button variant='outline-dark' disabled={index === 0} {...backProps}>
          Previous
        </Button>
        <Button
          className='ml-2'
          variant={isLastStep ? 'outline-dark' : 'secondary'}
          disabled={isLastStep}
          {...primaryProps}
        >
          Next
        </Button>
        <Button
          className='ml-4'
          variant={isLastStep ? 'secondary' : 'outline-dark'}
          {...closeProps}
        >
          End Tour
        </Button>
      </div>
    </Card.Body>
  </Card>
)

TourCard.propTypes = {
  imgSrcTour: PropTypes.string,
  showDisableTour: PropTypes.bool,
  disableTour: PropTypes.bool,
  onDisableTour: PropTypes.func
}

export { TourCard }
