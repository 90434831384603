import React from 'react'
import ClassNames from 'classnames'
import { DragSource, DropTarget } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import {
  BaseFileConnectors,
  BaseFolder,
  BaseFolderConnectors
} from 'react-keyed-file-browser'
import { format } from 'date-fns'

class RawFolderRenderer extends BaseFolder {
  render() {
    const {
      isOpen,
      isDragging,
      isDeleting,
      isRenaming,
      isDraft,
      isOver,
      isSelected,
      action,
      url,
      browserProps,
      connectDragPreview,
      depth,
      modified
    } = this.props

    const icon = browserProps.icons[isOpen ? 'FolderOpen' : 'Folder']
    const inAction = isDragging || action

    const ConfirmDeletionRenderer = browserProps.confirmDeletionRenderer

    let name
    if (!inAction && isDeleting) {
      name = (
        <ConfirmDeletionRenderer
          handleDeleteSubmit={this.handleDeleteSubmit}
          handleFileClick={this.handleFileClick}
          url={url}
        >
          {icon}
          {this.getName()}
        </ConfirmDeletionRenderer>
      )
    } else if ((!inAction && isRenaming) || isDraft) {
      name = (
        <div>
          <form className='renaming' onSubmit={this.handleRenameSubmit}>
            {icon}
            <input
              aria-label='Folder name'
              type='text'
              ref={(el) => {
                this.newNameRef = el
              }}
              value={this.state.newName}
              onChange={this.handleNewNameChange}
              onBlur={this.handleCancelEdit}
              autoFocus
            />
          </form>
        </div>
      )
    } else {
      name = (
        <div>
          <a
            role='link'
            tabIndex={0}
            onClick={this.toggleFolder}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                this.toggleFolder()
                this.handleFolderClick(event)
              }
            }}
          >
            {icon}
            {this.getName()}
          </a>
        </div>
      )
    }

    let draggable = <div>{name}</div>
    if (typeof browserProps.moveFile === 'function') {
      draggable = connectDragPreview(draggable)
    }

    const folder = (
      <tr
        className={ClassNames('folder', {
          pending: action,
          dragging: isDragging,
          dragover: isOver,
          selected: isSelected
        })}
        onClick={this.handleFolderClick}
        onDoubleClick={this.handleFolderDoubleClick}
      >
        <td className='name'>
          <div style={{ paddingLeft: depth * 16 + 'px' }}>{draggable}</div>
        </td>
        <td className='size' />
        <td className='modified'>
          {typeof modified === 'undefined' ? '-' : format(modified, 'PPp')}
        </td>
      </tr>
    )

    return this.connectDND(folder)
  }
}

const FolderRenderer = DragSource(
  'folder',
  BaseFolderConnectors.dragSource,
  BaseFolderConnectors.dragCollect
)(
  DropTarget(
    ['file', 'folder', NativeTypes.FILE],
    BaseFileConnectors.targetSource,
    BaseFileConnectors.targetCollect
  )(RawFolderRenderer)
)

export { FolderRenderer, RawFolderRenderer }
