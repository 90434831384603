import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Row } from 'react-bootstrap'
import {
  DenyRequestModal,
  SearchInput,
  SearchSplash,
  UserTable
} from '../../components'
import {
  useUserExportMutation,
  useUserMutation,
  useUserSearch
} from '../../hooks'
import { PAGE_STATE, PERMISSION, useStore } from '../../store'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const UserType = {
  New: 'pending',
  Existing: 'approved',
  All: ''
}

const UsersList = ({ userType }) => {
  const [search, setSearch] = useState('')
  const [noResultText, setNoResultText] = useState('')
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState([])
  const [refetchFlag, setRefetchFlag] = useState(false)
  const [showDenyRequestModal, setShowDenyRequestModal] = useState(false)
  const setPageState = useStore((state) => state.setPageState)
  const userArgsRef = useRef()

  const { users, pageCount, refetch } = useUserSearch({
    search,
    userType,
    page,
    sort
  })
  const { mutate: updateUser } = useUserMutation()
  const { mutate: exportUsers } = useUserExportMutation()

  useEffect(() => {
    setPage(1)
    setSort([])
    setSearch('')
    setNoResultText(
      userType === 'pending'
        ? 'There is no new user request'
        : 'Sorry, no results found'
    )
    setRefetchFlag(Boolean(userType))
  }, [userType])

  useEffect(() => {
    if (refetchFlag) {
      refetch()
      setRefetchFlag(false)
    }
  }, [refetch, refetchFlag])

  const usersLength = users?.length
  useEffect(() => {
    setPageState(usersLength ? PAGE_STATE.EDIT : PAGE_STATE.DEFAULT)
  }, [setPageState, usersLength])

  const handleExport = () => exportUsers({ search, sort, userType })

  const handleSearch = () => {
    setPage(1)
    setSort([])
    setRefetchFlag(true)
  }

  const handlePageChange = (data) => {
    const newPage = data.selected + 1
    if (newPage !== page) {
      setPage(data.selected + 1)
      setRefetchFlag(true)
    }
  }

  const handleSort = (data) => {
    if (data !== sort) {
      setPage(1)
      setSort(data)
      setRefetchFlag(true)
    }
  }

  const handleUserUpdate = () =>
    updateUser(userArgsRef.current, {
      onSuccess: () => {
        const user = users.find((user) => user.id === userArgsRef.current.id)
        user[userArgsRef.current.property] = userArgsRef.current.value
      }
    })

  const handleUpdate = (id, property, value) => {
    userArgsRef.current = {
      id,
      property,
      value
    }
    if (property === 'permission' && value === PERMISSION.DENIED) {
      setShowDenyRequestModal(true)
    } else {
      handleUserUpdate()
    }
  }

  const handleDenyRequestModalCancel = () => setShowDenyRequestModal(false)

  const handleDenyRequestModalConfirm = (args) => {
    setShowDenyRequestModal(false)
    userArgsRef.current = { ...userArgsRef.current, extra: args }
    handleUserUpdate()
  }

  return (
    <>
      <Row className='pb-3'>
        <Col xs={12} sm={8} md={6} lg={4}>
          <SearchInput
            placeholder='Search by name or email'
            search={search}
            onSearchChange={setSearch}
            onSearch={handleSearch}
          />
        </Col>
        <Col className='text-right'>
          <Button
            className='pl-0 pr-1 text-dark'
            onClick={handleExport}
            title='Export Data'
            variant='link'
          >
            <FontAwesomeIcon icon={faCloudDownloadAlt} /> Export Data
          </Button>
        </Col>
      </Row>
      {users ? (
        users.length ? (
          <UserTable
            users={users}
            page={page - 1}
            pageCount={pageCount}
            onPageChange={handlePageChange}
            sort={sort}
            onSort={handleSort}
            onUpdate={handleUpdate}
          />
        ) : (
          <SearchSplash text={noResultText} />
        )
      ) : (
        <SearchSplash text='Find a user among all registered users by searching for their name, username, or email' />
      )}
      <DenyRequestModal
        show={showDenyRequestModal}
        onCancel={handleDenyRequestModalCancel}
        onConfirm={handleDenyRequestModalConfirm}
      />
    </>
  )
}

UsersList.propTypes = { userType: PropTypes.oneOf(Object.values(UserType)) }

export { UsersList, UserType }
