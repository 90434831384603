import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form } from 'react-bootstrap'
import {
  faEye,
  faEyeSlash,
  faPlusCircle,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { Icon } from '../icon'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const INITIAL_DATA = {
  name: '',
  urlSlug: '',
  categories: [
    {
      orderNumber: 1,
      categoryType: 'ADMIN',
      name: 'Admin',
      hidden: false
    }
  ]
}

yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    if (
      value &&
      value[propertyName] &&
      this.parent
        .filter((v) => v !== value)
        .some(
          (v) =>
            String(v[propertyName]).toLowerCase() ===
            String(value[propertyName]).toLowerCase()
        )
    ) {
      return this.createError({ path: `${this.path}.${propertyName}` })
    }
    return true
  })
})

yup.addMethod(
  yup.object,
  'singleOccurrence',
  function (propertyName, propertyValue, message) {
    return this.test('singleOccurrence', message, function (value) {
      if (
        value &&
        value[propertyName] &&
        value[propertyName] === propertyValue &&
        this.parent
          .filter((v) => v !== value)
          .some(
            (v) =>
              String(v[propertyName]).toLowerCase() ===
              String(value[propertyName]).toLowerCase()
          )
      ) {
        return this.createError({ path: `${this.path}.${propertyName}` })
      }
      return true
    })
  }
)

const validationSchema = yup.object({
  name: yup.string().required('Group Name is required'),
  categories: yup.array(
    yup
      .object({
        orderNumber: yup.number().required('Category Order is required'),
        categoryType: yup.string().required('Category Type is required'),
        name: yup.string().required('Category Name is required')
      })
      .singleOccurrence(
        'categoryType',
        'DOCUMENT',
        'Only one occurrence of Document is allowed'
      )
      .uniqueProperty('orderNumber', 'Category Order must be unique')
      .uniqueProperty('name', 'Category Name must be unique')
  )
})

const TYPES = ['', 'DOCUMENT', 'STORY']
const isDisabledOption = (type) => !TYPES.includes(type)

const titleCase = (str) =>
  str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

const AffiliationForm = ({ formData = INITIAL_DATA, onSubmit }) => {
  const [disableDocumentCategory, setDisableDocumentCategory] = useState(false)
  const {
    control,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: formData,
    mode: 'all',
    resolver: yupResolver(validationSchema)
  })
  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'categories'
  })

  // keep categories up to date with form changes
  const watchFieldArray = watch('categories')
  const categories = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    }
  })

  // defaultValues are cached, so manually reset
  useEffect(() => reset(formData), [formData, reset])

  const name = watch('name')
  useEffect(() => {
    // if we don't already have a urlSlug, built it from the name
    if (!formData.urlSlug) {
      setValue('urlSlug', name.toLowerCase().replace(/ /g, '-'))
    }
  }, [formData.name, formData.urlSlug, name, setValue])

  useEffect(() => {
    // disable document category if we already have one
    setDisableDocumentCategory(
      categories.some((category) => category.categoryType === 'DOCUMENT')
    )
  }, [categories, categories.length, setValue])

  useEffect(() => {
    // keep the admin category last
    setValue(
      `categories.${categories.length - 1}.orderNumber`,
      categories.length
    )
  }, [categories.length, setValue])

  const onAddCategory = () => {
    const index = categories.length - 1
    insert(index, {
      orderNumber: index + 1,
      categoryType: '',
      name: '',
      hidden: false
    })
  }

  const onDeleteCategory = (index) => {
    const order = categories[index].orderNumber
    categories.forEach((category, i) => {
      if (category.orderNumber > order) {
        setValue(`categories.${i}.orderNumber`, category.orderNumber - 1)
      }
    })
    if (categories[index].categoryType === 'DOCUMENT') {
      setDisableDocumentCategory(false)
    }
    remove(index)
  }

  const onDiscard = () => {
    reset(formData)
  }

  return (
    <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Form.Control
        type='hidden'
        aria-label='url slug'
        {...register('urlSlug')}
      />
      <Form.Row className='mt-2'>
        <Col xs={5}>
          <Form.Group controlId='name'>
            <Form.Label className='font-weight-bold'>Group Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Group Name'
              {...register('name')}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={7}>
          <Form.Group controlId='groupUrl'>
            <Form.Label className='font-weight-bold'>
              URL (by default)
            </Form.Label>
            <Form.Control
              type='text'
              disabled
              value={`${window.location.host}/${watch('urlSlug')}`}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className='mt-2'>
        <div className='font-weight-bold'>Categories</div>
        <div className='border-bottom pb-2'>
          <small className='muted'>
            Add the categories(pages) you would like to include in the portal.
            Note: An Admin page for user management is automatically added as
            the portal's last category.
          </small>
        </div>
      </Form.Row>
      <Form.Row className='mt-2'>
        <Col xs={2}>Category Order</Col>
        <Col xs={3}>Category Type</Col>
        <Col xs={4}>Category Name</Col>
        <Col xs={3}>Actions</Col>
      </Form.Row>
      {categories.map((category, index) => (
        <Form.Row key={category.id} className='mt-2'>
          <Col xs={2}>
            <Form.Group controlId={`categories.${index}.orderNumber`}>
              <Form.Control
                as='select'
                aria-label='order'
                {...register(`categories.${index}.orderNumber`, {
                  valueAsNumber: true,
                  onChange: () => trigger()
                })}
                disabled={category.categoryType === 'ADMIN'}
                isInvalid={
                  errors.categories && !!errors.categories[index]?.orderNumber
                }
                className='mr-sm-2'
              >
                {Array(
                  category.categoryType === 'ADMIN'
                    ? categories.length
                    : categories.length - 1
                )
                  .fill()
                  .map((_, i) => (
                    <option key={i + 1} value={i + 1}>
                      #{i + 1}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type='invalid'>
                {errors.categories &&
                  errors.categories[index]?.orderNumber?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group controlId={`categories.${index}.categoryType`}>
              <Form.Control
                as='select'
                aria-label='type'
                {...register(`categories.${index}.categoryType`)}
                disabled={
                  isDisabledOption(category.categoryType) ||
                  getValues(`categories.${index}.id`)
                }
                defaultValue=''
                isInvalid={
                  errors.categories && !!errors.categories[index]?.categoryType
                }
                className='mr-sm-2'
              >
                <option value='' disabled>
                  Select a category type
                </option>
                {isDisabledOption(category.categoryType) ? (
                  <option value={category.categoryType}>
                    {titleCase(category.categoryType)}
                  </option>
                ) : null}
                <option value='STORY'>Story</option>
                <option value='DOCUMENT' disabled={disableDocumentCategory}>
                  Document (only one occurrence is allowed)
                </option>
              </Form.Control>
              <Form.Control.Feedback type='invalid'>
                {errors.categories &&
                  errors.categories[index]?.categoryType?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Control
              type='text'
              aria-label='name'
              {...register(`categories.${index}.name`, {
                onChange: () => trigger()
              })}
              disabled={category.categoryType === 'ADMIN'}
              isInvalid={errors.categories && !!errors.categories[index]?.name}
              placeholder='Name the category'
            />
            <Form.Control.Feedback type='invalid'>
              {errors.categories && errors.categories[index]?.name?.message}
            </Form.Control.Feedback>
          </Col>
          <Col xs={3}>
            <Controller
              control={control}
              name={`categories.${index}.hidden`}
              render={({ field: { onChange, value } }) => (
                <Button
                  variant='link'
                  onClick={() => onChange(!value)}
                  disabled={category.categoryType === 'ADMIN'}
                  className='text-muted'
                >
                  <Icon icon={value ? faEye : faEyeSlash} size='lg' />
                  {value ? 'Unhide' : 'Hide'}
                </Button>
              )}
            />
            |
            <Button
              variant='link'
              onClick={() => onDeleteCategory(index)}
              disabled={category.categoryType === 'ADMIN'}
              className='text-muted'
            >
              <Icon icon={faTrash} size='lg' />
              Delete
            </Button>
          </Col>
        </Form.Row>
      ))}
      <Form.Row>
        <Button variant='link' onClick={onAddCategory}>
          <Icon
            icon={faPlusCircle}
            size='2x'
            className='align-middle text-secondary'
          />
          <span className='align-middle text-dark'>Add Category</span>
        </Button>
      </Form.Row>
      <Form.Row className='mt-3 justify-content-end'>
        <Button variant='secondary' type='submit'>
          {formData.id ? 'Save Changes' : 'Create Group'}
        </Button>
        <Button variant='outline-dark' onClick={onDiscard} className='ml-3'>
          {formData.id ? 'Cancel' : 'Discard'}
        </Button>
      </Form.Row>
    </Form>
  )
}

AffiliationForm.propTypes = {
  formData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    urlSlug: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        orderNumber: PropTypes.number.isRequired,
        categoryType: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        hidden: PropTypes.bool,
        urlSlug: PropTypes.string.isRequired
      })
    )
  }),
  onSubmit: PropTypes.func.isRequired
}

export { AffiliationForm }
