import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const LiUpload = styled.li`
  input[type='file'] {
    display: none;
  }
  label:hover {
    text-decoration: underline;
  }
`
/**
 * Adapted from react-keyed-file-browser/src/actions/default.js
 */
const ActionRenderer = (props) => {
  const {
    selectedItem,
    isFolder,
    icons,
    nameFilter,

    canCreateFolder,
    onCreateFolder,

    canRenameFile,
    onRenameFile,

    canRenameFolder,
    onRenameFolder,

    canDeleteFile,
    onDeleteFile,

    canDeleteFolder,
    onDeleteFolder,

    canDownloadFile,
    onDownloadFile
  } = props

  const onCreateFiles = props.browserProps && props.browserProps.createFiles
  const canCreateFiles = Boolean(onCreateFiles)

  const copyToClipboard = async (link) => {
    try {
      await navigator.clipboard.writeText(link)
    } catch (err) {
      console.error('Unable to copy link')
    }
  }

  const onAddFiles = (event) => {
    const files = Array.prototype.slice.call(event.target.files)
    const path = props.selectedItem ? props.selectedItem.key : ''
    files && onCreateFiles(files, path)
  }

  const AddFiles = () => {
    const fileInput = useRef()

    return (
      <LiUpload>
        <label>
          <span
            role='button'
            aria-controls='filename'
            tabIndex='0'
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                fileInput.current.click()
              }
            }}
          >
            {icons.Upload}
            &nbsp;Add Files
          </span>
          <input
            type='file'
            ref={fileInput}
            multiple
            onChange={onAddFiles}
            tabIndex='-1'
          />
        </label>
      </LiUpload>
    )
  }

  let actions = []

  if (selectedItem) {
    // Something is selected. Build custom actions depending on what it is.
    if (selectedItem.action) {
      // Selected item has an active action against it. Disable all other actions.
      let actionText
      switch (selectedItem.action) {
        case 'delete':
          actionText = 'Deleting ...'
          break

        case 'rename':
          actionText = 'Renaming ...'
          break

        default:
          actionText = 'Moving ...'
          break
      }

      actions = (
        // TODO: Enable plugging in custom spinner.
        <div className='item-actions'>
          {icons.Loading} {actionText}
        </div>
      )
    } else {
      if (!isFolder) {
        actions.push(
          <li key='action-copylink'>
            <a
              onClick={(event) => {
                event.preventDefault()
                copyToClipboard(selectedItem.url)
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  event.preventDefault()
                  copyToClipboard(selectedItem.url)
                }
              }}
              href='/#'
              role='button'
            >
              {icons.CopyLink}
              &nbsp;Copy Link
            </a>
          </li>
        )
      }

      if (isFolder && canCreateFiles) {
        actions.push(<AddFiles key='action-upload' />)
      }

      if (isFolder && canCreateFolder && !nameFilter) {
        actions.push(
          <li key='action-add-folder'>
            <a
              onClick={onCreateFolder}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  event.preventDefault()
                  onCreateFolder(event)
                }
              }}
              href='/#'
              role='button'
            >
              {icons.Folder}
              &nbsp;Add Subfolder
            </a>
          </li>
        )
      }

      if (selectedItem.keyDerived && !isFolder && canRenameFile) {
        actions.push(
          <li key='action-rename'>
            <a
              onClick={onRenameFile}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  event.preventDefault()
                  onRenameFile(event)
                }
              }}
              href='/#'
              role='button'
            >
              {icons.Rename}
              &nbsp;Rename
            </a>
          </li>
        )
      } else if (selectedItem.keyDerived && isFolder && canRenameFolder) {
        actions.push(
          <li key='action-rename'>
            <a
              onClick={onRenameFolder}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  event.preventDefault()
                  onRenameFolder(event)
                }
              }}
              href='/#'
              role='button'
            >
              {icons.Rename}
              &nbsp;Rename
            </a>
          </li>
        )
      }

      if (selectedItem.keyDerived && !isFolder && canDeleteFile) {
        actions.push(
          <li key='action-delete'>
            <a
              onClick={onDeleteFile}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  event.preventDefault()
                  onDeleteFile(event)
                }
              }}
              href='/#'
              role='button'
            >
              {icons.Delete}
              &nbsp;Delete
            </a>
          </li>
        )
      } else if (selectedItem.keyDerived && isFolder && canDeleteFolder) {
        actions.push(
          <li key='action-delete'>
            <a
              onClick={onDeleteFolder}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  event.preventDefault()
                  onDeleteFolder(event)
                }
              }}
              href='/#'
              role='button'
            >
              {icons.Delete}
              &nbsp;Delete
            </a>
          </li>
        )
      }

      if (!isFolder && canDownloadFile) {
        actions.push(
          <li key='action-download'>
            <a
              onClick={onDownloadFile}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  event.preventDefault()
                  onDownloadFile(event)
                }
              }}
              href='/#'
              role='button'
            >
              {icons.Download}
              &nbsp;Download
            </a>
          </li>
        )
      }

      if (actions.length) {
        actions = <ul className='item-actions'>{actions}</ul>
      } else {
        actions = <div className='item-actions'>&nbsp;</div>
      }
    }
  } else {
    // Nothing selected: We're in the 'root' folder.
    if (canCreateFiles) {
      actions.push(<AddFiles key='action-upload' />)
    }

    if (canCreateFolder && !nameFilter) {
      actions.push(
        <li key='action-add-folder'>
          <a
            onClick={onCreateFolder}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault()
                onCreateFolder(event)
              }
            }}
            href='/#'
            role='button'
          >
            {icons.Folder}
            &nbsp;Add Folder
          </a>
        </li>
      )
    }

    if (actions.length) {
      actions = <ul className='item-actions'>{actions}</ul>
    } else {
      actions = <div className='item-actions'>&nbsp;</div>
    }
  }

  return actions
}

ActionRenderer.propTypes = {
  selectedItem: PropTypes.object,
  isFolder: PropTypes.bool,
  icons: PropTypes.object,
  nameFilter: PropTypes.string,

  canCreateFolder: PropTypes.bool,
  onCreateFolder: PropTypes.func,

  canRenameFile: PropTypes.bool,
  onRenameFile: PropTypes.func,

  canRenameFolder: PropTypes.bool,
  onRenameFolder: PropTypes.func,

  canDeleteFile: PropTypes.bool,
  onDeleteFile: PropTypes.func,

  canDeleteFolder: PropTypes.bool,
  onDeleteFolder: PropTypes.func,

  canDownloadFile: PropTypes.bool,
  onDownloadFile: PropTypes.func
}

ActionRenderer.defaultProps = {
  selectedItem: null,
  isFolder: false,
  icons: {},
  nameFilter: '',

  canCreateFolder: false,
  onCreateFolder: null,

  canRenameFile: false,
  onRenameFile: null,

  canRenameFolder: false,
  onRenameFolder: null,

  canDeleteFile: false,
  onDeleteFile: null,

  canDeleteFolder: false,
  onDeleteFolder: null,

  canDownloadFile: false,
  onDownloadFile: null
}

export { ActionRenderer }
