import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from 'react-oauth2'
import { env } from '../../helpers'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useAccessRequests = () => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)

  const getAccessRequests = async () => {
    const url = encodeURI(`${env.apiUrl}/accessRequests`)
    const response = await securedFetch(url)
    await handleResponse(response)
    const data = await response.json()
    const options = data
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.name,
          disabled: Boolean(item.permission)
        }
      })
    const selected = options.filter((item) => item.disabled)
    return { options, selected }
  }

  const query = useQuery('accessRequests', getAccessRequests)

  useEffect(() => {
    if (query.status === 'loading') {
      progressStart()
    } else if (query.status === 'success') {
      progressEnd()
    } else if (query.status === 'error') {
      handleError(query.error, 'Error getting access requests')
      progressEnd()
    }
  }, [handleError, progressEnd, progressStart, query.error, query.status])

  return { ...query.data }
}

export { useAccessRequests }
