import React from 'react'
import { TitleToolbar } from './TitleToolbar'
import { useStore } from '../../store'

const Toolbar = (props) => {
  const imgSrcLogo = useStore((state) => state.imgSrcLogo)
  const imgAltLogo = useStore((state) => state.imgAltLogo)
  return (
    <TitleToolbar
      collapseOnSelect
      className='title-toolbar'
      variant='dark'
      logoSrc={imgSrcLogo}
      logoAlt={imgAltLogo}
      {...props}
    />
  )
}

export { Toolbar }
