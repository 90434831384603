import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from 'react-oauth2'
import { env } from '../../helpers'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useVisualization = (visualizationId) => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const setDisabled = useStore((state) => state.setDisabled)
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)

  const getVisualization = async () => {
    const url = encodeURI(`${env.apiUrl}/visualizations/${visualizationId}`)
    const response = await securedFetch(url)
    await handleResponse(response)
    return response.json()
  }

  const query = useQuery(['visualization', visualizationId], getVisualization, {
    cacheTime: 0,
    enabled: !!visualizationId,
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (query.status === 'loading') {
      progressStart()
      setDisabled(true)
    } else if (query.status === 'success') {
      progressEnd()
      setDisabled(false)
    } else if (query.status === 'error') {
      handleError(query.error, 'Error getting visualization')
      progressEnd()
      setDisabled(false)
    }
  }, [
    handleError,
    progressEnd,
    progressStart,
    query.error,
    query.status,
    setDisabled
  ])

  return {
    visualization: query.data
  }
}

export { useVisualization }
