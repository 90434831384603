import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from 'react-oauth2'
import { env } from '../../helpers'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useVisualizations = (categoryId) => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()

  const getVisualizations = async () => {
    const url = encodeURI(
      `${env.apiUrl}/categories/${categoryId}/visualizations`
    )
    const response = await securedFetch(url)
    await handleResponse(response)
    return response.json()
  }

  const query = useQuery(['visualizations', categoryId], getVisualizations, {
    enabled: !!categoryId,
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    // don't set progress start/end because this query is
    // immediately followed by getting a single visualization
    // and we don't want the bar bouncing up and down
    if (query.status === 'error') {
      handleError(query.error, 'Error getting visualizations')
    }
  }, [handleError, query.error, query.status])

  return {
    visualizations: query.data
  }
}

export { useVisualizations }
