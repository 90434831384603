import React from 'react'
import Alert from 'react-bootstrap/Alert'
import { Icon } from '../icon'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { useStore } from '../../store'

const DangerAlert = () => {
  const error = useStore((state) => state.error)
  const setError = useStore((state) => state.setError)

  return error ? (
    <Alert variant='danger' dismissible onClose={() => setError('')}>
      <Icon icon={faExclamationCircle} size='lg' />
      {error}
    </Alert>
  ) : null
}

export { DangerAlert }
