import React, { useEffect, useRef } from 'react'
import { propTypes } from '../../helpers'
import { useStore } from '../../store'

const VisualizationTableau = ({ visualization }) => {
  const visDiv = useRef()
  const setError = useStore((state) => state.setError)

  useEffect(() => {
    try {
      window.tableau.VizManager.getVizs().forEach((viz) => viz.dispose())
      /* eslint-disable no-new */
      new window.tableau.Viz(visDiv.current, visualization.content, {
        width: '100%',
        height: '950px',
        hideTabs: true,
        hideToolbar: true
      })
    } catch (error) {
      console.error('Tableau Error', error)
      setError('Tableau Error')
    }
  }, [setError, visualization.content])

  return <div ref={visDiv} />
}

VisualizationTableau.propTypes = {
  visualization: propTypes.visualizationPropType.isRequired
}
export { VisualizationTableau }
