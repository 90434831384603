import { env } from '../../helpers'
import { useMutation, useQueryClient } from 'react-query'
import { useAuth } from 'react-oauth2'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useDeleteAffiliationMutation = () => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const setMessage = useStore((state) => state.setMessage)
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)
  const queryClient = useQueryClient()

  const deleteAffiliation = async (id) => {
    const url = encodeURI(`${env.apiUrl}/affiliations/${id}`)
    const response = await securedFetch(url, { method: 'DELETE' })
    await handleResponse(response)
  }

  return useMutation(({ id }) => deleteAffiliation(id), {
    onMutate: progressStart,
    onError: (error) => handleError(error, 'Error deleting group'),
    onSuccess: () => {
      setMessage('Group was deleted')
      queryClient.invalidateQueries('affiliations')
      queryClient.invalidateQueries('admin-affiliations')
    },
    onSettled: progressEnd
  })
}

export { useDeleteAffiliationMutation }
