import { useMutation } from 'react-query'
import { useAuth } from 'react-oauth2'
import { env } from '../../helpers'
import { useStore } from '../../store'
import { format } from 'date-fns'
import fileDownload from 'js-file-download'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

export const useUserExportMutation = () => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const selectedAffiliation = useStore((state) => state.selectedAffiliation)
  const setDisabled = useStore((state) => state.setDisabled)
  const setMessage = useStore((state) => state.setMessage)
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)

  const getExport = async (search, sort, userType) => {
    const api = encodeURI(
      `${env.apiUrl}/affiliations/${selectedAffiliation.id}/users/export`
    )
    let params = search ? `&search=${search}` : ''
    params += sort.reduce((result, item) => {
      result += `&sort.${item.id}=${item.desc ? 'desc' : 'asc'}`
      return result
    }, '')
    params += userType ? `&userType=${userType}` : ''
    const url = [api, params.slice(1)].join('?')
    const response = await securedFetch(url)
    await handleResponse(response)
    return response.blob()
  }

  return useMutation(
    ({ search, sort, userType }) => getExport(search, sort, userType),
    {
      onMutate: () => {
        progressStart()
        setDisabled(true)
        setMessage('Downloading...')
      },
      onError: (error) => handleError(error, 'Error downloading document'),
      onSuccess: (data) => {
        fileDownload(
          data,
          `${format(new Date(), 'yyyyMMdd-HHmmss')}-${
            selectedAffiliation.urlSlug
          }-users.csv`
        )
        setMessage('Document was downloaded')
      },
      onSettled: () => {
        progressEnd()
        setDisabled(false)
      }
    }
  )
}
