import { env } from '../../helpers'
import { useMutation, useQueryClient } from 'react-query'
import { useAuth } from 'react-oauth2'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useDisableTourMutation = () => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const queryClient = useQueryClient()

  const setDisableTour = async (value) => {
    const url = encodeURI(`${env.apiUrl}/userInfo`)
    const body = { enableTour: !value }
    const response = await securedFetch(url, {
      method: 'PATCH',
      body: JSON.stringify(body)
    })
    await handleResponse(response)
  }

  return useMutation(({ value }) => setDisableTour(value), {
    onMutate: ({ value }) => {
      queryClient.cancelQueries('user')
      queryClient.setQueryData('user', (user) => ({
        ...user,
        disableTour: value
      }))
    },
    onError: (error) => handleError(error, 'Error updating tour'),
    onSettled: () => queryClient.invalidateQueries('user')
  })
}

export { useDisableTourMutation }
