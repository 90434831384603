import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { MultiSelect as MultiSelectComponent } from 'react-multi-select-component'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const MultiSelectContainer = styled.div`
  .tags {
    .tag {
      display: inline-block;
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
      padding: 0.25rem 0.4rem;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25rem;
    }
  }
  .multi-select {
    .dropdown-heading {
      padding-left: 0;
      .form-control {
        border: 0;
      }
      .clear-selected-button {
        display: none;
      }
    }
    .select-panel label {
      margin-bottom: 0;
    }
  }
`

const MultiSelect = ({ labelledBy, options, selected, onChange }) => {
  const [filter, setFilter] = useState()

  return (
    <MultiSelectContainer>
      <div className='tags'>
        {selected
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((item) => (
            <span key={item.label} className='alert-secondary tag'>
              {item.label}
            </span>
          ))}
      </div>
      <MultiSelectComponent
        labelledBy={labelledBy}
        options={
          filter
            ? options.filter(({ label }) =>
                label.match(new RegExp('^' + filter, 'i'))
              )
            : options
        }
        value={selected}
        onChange={onChange}
        disableSearch
        hasSelectAll={false}
        isOpen
        valueRenderer={() => (
          <Form.Control
            type='text'
            aria-label='Filter'
            onChange={(e) => setFilter(e.target.value)}
            placeholder='Filter'
          />
        )}
      />
    </MultiSelectContainer>
  )
}

const OptionPropType = PropTypes.shape({
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
})

MultiSelect.propTypes = {
  labelledBy: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(OptionPropType).isRequired,
  selected: PropTypes.arrayOf(OptionPropType).isRequired,
  onChange: PropTypes.func.isRequired
}

export { MultiSelect }
