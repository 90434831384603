import { dataToDocument } from './dataToDocument'
import { useMutation, useQueryClient } from 'react-query'
import { useAuth } from 'react-oauth2'
import { env } from '../../helpers'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useSaveDocumentsMutation = () => {
  const { getAuthHeaders } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const setDisabled = useStore((state) => state.setDisabled)
  const documentCategory = useStore((state) => state.documentCategory)
  const setMessage = useStore((state) => state.setMessage)
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)
  const setProgress = useStore((state) => state.setProgress)
  const categoryId = documentCategory && documentCategory.id
  const queryClient = useQueryClient()

  const saveDocument = async (path, file, updateProgress) => {
    const url = encodeURI(`${env.apiUrl}/categories/${categoryId}/documents`)
    const formData = new FormData()
    formData.append('path', path)
    file && formData.append('uploadFile', file)
    const { Authorization } = await getAuthHeaders()

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.upload.onprogress = (event) => {
        file && updateProgress(file.name, event.loaded, event.total)
      }
      xhr.onloadend = async () => {
        try {
          await handleResponse({
            ok: xhr.status < 300,
            json: () => JSON.parse(xhr.responseText),
            status: xhr.status,
            statusText: xhr.statusText
          })
          resolve(dataToDocument(xhr.response))
        } catch (error) {
          reject(error)
        }
      }
      xhr.open('POST', url)
      xhr.setRequestHeader('Authorization', Authorization)
      xhr.responseType = 'json'
      xhr.send(formData)
    })
  }

  return useMutation(
    ({ files, path }) => {
      const promises = []
      if (!files) {
        promises.push(saveDocument(path))
      } else {
        const current = {}
        files.forEach(
          (file) => (current[file.name] = { loaded: 0, total: file.size })
        )
        const progressReducer = (previous, current) => ({
          loaded: previous.loaded + current.loaded,
          total: previous.total + current.total
        })
        const updateProgress = (name, loaded, total) => {
          current[name] = { loaded, total }
          const summed = Object.values(current).reduce(progressReducer)
          setProgress((100 * summed.loaded) / summed.total)
        }
        files.forEach((file) => {
          promises.push(saveDocument(path, file, updateProgress))
        })
      }
      return Promise.all(promises)
    },
    {
      onMutate: ({ files }) => {
        !files && progressStart()
        setDisabled(true)
      },
      onError: (error) => handleError(error, 'Error saving document'),
      onSuccess: () => setMessage('Document was saved'),
      onSettled: () => {
        progressEnd()
        queryClient.invalidateQueries('documents')
        setDisabled(false)
      }
    }
  )
}

export { useSaveDocumentsMutation }
