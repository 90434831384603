export const env = {
  authUrl: process.env.REACT_APP_AUTH_URL,
  tokenUrl: process.env.REACT_APP_TOKEN_URL,
  logoutUrl: process.env.REACT_APP_LOGOUT_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUrl: process.env.REACT_APP_REDIRECT_URL,
  scope: process.env.REACT_APP_SCOPE,
  accountUrl: process.env.REACT_APP_ACCOUNT_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  publicApiUrl: process.env.REACT_APP_PUBLIC_API_URL,
  helpUrl: process.env.REACT_APP_HELP_URL,
  contactUrl: process.env.REACT_APP_CONTACT_URL,
  gtmId: process.env.REACT_APP_GTM_ID,
  supportUrl: process.env.REACT_APP_SUPPORT_URL,
  calendarUrl: process.env.REACT_APP_CALENDAR_URL
}
