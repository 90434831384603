import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from 'react-oauth2'
import { env } from '../../helpers'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useCategories = () => {
  const { loggedIn, securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const selectedAffiliation = useStore((state) => state.selectedAffiliation)

  const getCategories = async () => {
    const fetchFn = loggedIn ? securedFetch : fetch
    const url = encodeURI(
      loggedIn
        ? `${env.apiUrl}/affiliations/${selectedAffiliation.id}/userCategories`
        : `${env.publicApiUrl}/affiliations/${selectedAffiliation.id}/categories`
    )
    const response = await fetchFn(url)
    await handleResponse(response)
    return response.json()
  }

  const query = useQuery(
    ['categories', selectedAffiliation?.id],
    getCategories,
    {
      enabled: !!selectedAffiliation
    }
  )

  useEffect(() => {
    query.status === 'error' &&
      handleError(query.error, 'Error getting categories')
  }, [handleError, query.error, query.status])

  return {
    categories: query.data
  }
}

export { useCategories }
