import React from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup, OverlayTrigger, Popover } from 'react-bootstrap'

const ConfirmPopover = ({ id, children, onConfirm }) => {
  const closePopup = () => document.body.click()

  const onNoClick = () => closePopup()

  const onYesClick = () => {
    onConfirm()
    closePopup()
  }

  return (
    <OverlayTrigger
      trigger='click'
      rootClose
      placement='auto'
      overlay={
        <Popover id={id}>
          <Popover.Title as='strong'>Are you sure?</Popover.Title>
          <Popover.Content className='text-center'>
            <ButtonGroup>
              <Button variant='danger' onClick={onYesClick}>
                Yes
              </Button>
              <Button variant='outline-dark' onClick={onNoClick}>
                No
              </Button>
            </ButtonGroup>
          </Popover.Content>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

ConfirmPopover.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired
}

export { ConfirmPopover }
