import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSortBy, useTable } from 'react-table'
import { Form, Table } from 'react-bootstrap'
import { format, parseISO } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faCaretUp,
  faSort
} from '@fortawesome/free-solid-svg-icons'
import ReactPaginate from 'react-paginate'
import { PERMISSION } from '../../store'
import styled from 'styled-components'

const StyledIcon = styled(FontAwesomeIcon)`
  color: darkGray;
`
const StyledTh = styled.th`
  white-space: nowrap;
`

const UserTable = ({
  users,
  page,
  pageCount,
  onPageChange,
  sort,
  onSort,
  onUpdate
}) => {
  const columns = useMemo(
    () => [
      { Header: 'FirstName', accessor: 'firstName' },
      { Header: 'LastName', accessor: 'lastName' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Username', accessor: 'username' },
      { Header: 'User ID', accessor: 'userId' },
      {
        Header: 'Last Login',
        accessor: 'lastLogin',
        Cell: ({ value }) => {
          try {
            return value ? format(parseISO(value), 'Pp') : null
          } catch (e) {
            console.error('Error parsing lastUpdated', value, e.stack)
            return null
          }
        }
      },
      {
        Header: 'Permission',
        accessor: 'permission',
        Cell: ({ row, value }) => (
          <Form.Control
            as='select'
            value={value}
            aria-label='permission'
            onChange={(event) =>
              onUpdate(row.original.id, 'permission', event.target.value)
            }
          >
            <option value={PERMISSION.UNASSIGNED}>Unassigned</option>
            <option value={PERMISSION.VIEW}>Viewer</option>
            <option value={PERMISSION.EDIT}>Editor</option>
            <option value={PERMISSION.ADMIN}>Admin</option>
            {value === PERMISSION.UNASSIGNED || value === PERMISSION.DENIED ? (
              <option value={PERMISSION.DENIED}>Deny Request</option>
            ) : null}
          </Form.Control>
        )
      }
    ],
    [onUpdate]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
    setSortBy
  } = useTable(
    {
      columns,
      data: users,
      initialState: { sortBy: sort },
      manualSortBy: true,
      onUpdate
    },
    useSortBy
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setSortBy(sort), [sort])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onSort(sortBy), [sortBy])

  return (
    <>
      <Table
        responsive
        bordered
        hover
        striped
        size='sm'
        className='user-admin-table'
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <StyledTh
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: undefined })
                  )}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size='lg'
                        className='ml-1'
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size='lg'
                        className='ml-1'
                      />
                    )
                  ) : (
                    <StyledIcon icon={faSort} size='lg' className='ml-1' />
                  )}
                </StyledTh>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        previousLabel='<'
        nextLabel='>'
        breakClassName='page-item'
        breakLinkClassName='page-link'
        onPageChange={onPageChange}
        forcePage={page}
        containerClassName='pagination justify-content-center'
        pageClassName='page-item'
        pageLinkClassName='page-link'
        activeClassName='active'
        previousClassName='page-item'
        nextClassName='page-item'
        previousLinkClassName='page-link'
        nextLinkClassName='page-link'
      />
    </>
  )
}

UserTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      desc: PropTypes.bool
    }).isRequired
  ).isRequired,
  onSort: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
}

export { UserTable }
