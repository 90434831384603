import React from 'react'
import ClassNames from 'classnames'
import { DragSource, DropTarget } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { BaseFile, BaseFileConnectors } from 'react-keyed-file-browser'
import { fileSize } from './utils'
import { format } from 'date-fns'

/**
 * Adapted from react-keyed-file-browser/src/files/table.js
 */
class RawFileRenderer extends BaseFile {
  render() {
    const {
      isDragging,
      isDeleting,
      isRenaming,
      isOver,
      isSelected,
      action,
      url,
      browserProps,
      connectDragPreview,
      depth,
      size,
      modified
    } = this.props

    const icon =
      browserProps.icons[this.getFileType()] || browserProps.icons.File
    const inAction = isDragging || action

    const ConfirmDeletionRenderer = browserProps.confirmDeletionRenderer

    let name
    if (!inAction && isDeleting) {
      name = (
        <ConfirmDeletionRenderer
          handleDeleteSubmit={this.handleDeleteSubmit}
          handleFileClick={this.handleFileClick}
          url={url}
        >
          {icon}
          {this.getName()}
        </ConfirmDeletionRenderer>
      )
    } else if (!inAction && isRenaming) {
      name = (
        <form className='renaming' onSubmit={this.handleRenameSubmit}>
          {icon}
          <input
            aria-label='File name'
            ref={(el) => {
              this.newNameRef = el
            }}
            type='text'
            value={this.state.newName}
            onChange={this.handleNewNameChange}
            onBlur={this.handleCancelEdit}
            autoFocus
          />
        </form>
      )
    } else {
      name = (
        <a
          href={url || '#'}
          data-name={this.getName()}
          download='download'
          onClick={this.handleFileClick}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              this.handleFileClick(event)
              this.handleItemClick(event)
            }
          }}
        >
          {icon}
          {this.getName()}
        </a>
      )
    }

    let draggable = <div>{name}</div>
    if (typeof browserProps.moveFile === 'function') {
      draggable = connectDragPreview(draggable)
    }

    const row = (
      <tr
        className={ClassNames('file', {
          pending: action,
          dragging: isDragging,
          dragover: isOver,
          selected: isSelected
        })}
        onClick={this.handleItemClick}
        onDoubleClick={this.handleItemDoubleClick}
      >
        <td className='name'>
          <div style={{ paddingLeft: depth * 16 + 'px' }}>{draggable}</div>
        </td>
        <td className='size'>{fileSize(size)}</td>
        <td className='modified'>
          {typeof modified === 'undefined' ? '-' : format(modified, 'PPp')}
        </td>
      </tr>
    )

    return this.connectDND(row)
  }
}

const FileRenderer = DragSource(
  'file',
  BaseFileConnectors.dragSource,
  BaseFileConnectors.dragCollect
)(
  DropTarget(
    ['file', 'folder', NativeTypes.FILE],
    BaseFileConnectors.targetSource,
    BaseFileConnectors.targetCollect
  )(RawFileRenderer)
)

export { FileRenderer, RawFileRenderer }
