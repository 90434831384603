import { env } from '../../helpers'
import { useMutation, useQueryClient } from 'react-query'
import { useAuth } from 'react-oauth2'
import { useStore } from '../../store'
import { useErrorHandler } from '../useErrorHandler'
import { useResponseHandler } from '../useResponseHandler'

const useDeleteDocumentMutation = () => {
  const { securedFetch } = useAuth()
  const { handleError } = useErrorHandler()
  const { handleResponse } = useResponseHandler()
  const setDisabled = useStore((state) => state.setDisabled)
  const setMessage = useStore((state) => state.setMessage)
  const progressStart = useStore((state) => state.progressStart)
  const progressEnd = useStore((state) => state.progressEnd)
  const queryClient = useQueryClient()

  const deleteDocument = async (documentId) => {
    const url = encodeURI(`${env.apiUrl}/documents/${documentId}`)
    const response = await securedFetch(url, { method: 'DELETE' })
    await handleResponse(response)
  }

  return useMutation(({ documentId }) => deleteDocument(documentId), {
    onMutate: () => {
      progressStart()
      setDisabled(true)
    },
    onError: (error) => handleError(error, 'Error deleting document'),
    onSuccess: () => {
      queryClient.invalidateQueries('documents')
      setMessage('Document was deleted')
    },
    onSettled: () => {
      progressEnd()
      setDisabled(false)
    }
  })
}

export { useDeleteDocumentMutation }
