const useResponseHandler = () => {
  const handleResponse = async (response) => {
    if (!response.ok) {
      const json = await response.json()
      let errors =
        json._embedded && json._embedded.errors
          ? json._embedded.errors.map((item) => item.message).join('\n')
          : json.message
      if (!errors) {
        errors = `Service returned ${response.status} ${response.statusText}`
      }
      throw new Error(errors)
    }
  }

  return { handleResponse }
}

export { useResponseHandler }
